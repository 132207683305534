import { useTypeSelector } from "@/hooks/useTypeSelector"
import React, { useEffect, useRef, useState } from "react"
import { Layer, Stage, Text, Transformer } from "react-konva"
import toast from "react-hot-toast"
import { ToastContainer, toast as toastify } from "react-toastify"
import { useActions } from "@/hooks/useActions"
import { useNavigate, useParams } from "react-router-dom"
import Konva from "konva"
import Select, { SelectInstance } from "react-select"
import { useAppDispatch, useAppSelector } from "@/redux/store"
import { QRCodeSVG } from "qrcode.react"
import {
  sendQrDesign,
  setLoading,
  updateQrDesign,
  uploadQRImage,
} from "@/redux/slices/events/eventsSlice"
import { URLImage } from "./QRCodeDownLoad"
import { QRCodeImageButton } from "./QRCodeImageButton"
import { QRLayer } from "@/components/QrDesign/canvas/ResizableQRBlock"
import { QRBlock } from "@/components/QrDesign/canvas/QrTypes"
import { CounterLayer } from "@/components/QrDesign/canvas/ResizableCounterBlock"
import api from "@/api/api"
import posthog from "posthog-js"
import Preloader from "@/components/utils/Preloader/Preloader"

const guests = [
  {
    value: "Mrs Sama Al-Rayez",
    label: "Mrs Sama Al-Rayez",
    type: "suffix_full_name",
  },
  { value: "Mrs Sama", label: "Mrs Sama", type: "suffix_name" },
  { value: "Mrs Al-Rayez", label: "Mrs Al-Rayez", type: "suffix_surname" },
  {
    value: "Sama Al-Rayez",
    label: "Sama Al-Rayez",
    type: "full_name",
  },
  { value: "Sama", label: "Sama", type: "first_name" },
  { value: "Al-Rayez", label: "Al-Rayez", type: "surname" },
]

const alignOptions = [
  {
    value: "left",
    label: <img src="/vector/QrDesign/alignt_format_left.svg" />,
  },
  {
    value: "center",
    label: <img src="/vector/QrDesign/alignt_format_center.svg" />,
  },
  {
    value: "right",
    label: <img src="/vector/QrDesign/alignt_format_right.svg" />,
  },
]

const fontOptions = [
  {
    label: "Montserrat",
    value: "Montserrat",
  },
  {
    label: "Inter",
    value: "Inter",
  },
  {
    label: "Roboto",
    value: "Roboto",
  },
  {
    label: "SF Pro Display",
    value: "SF Pro Display",
  },
  {
    label: "Barlow",
    value: "Barlow",
  },
]

const getContent = (obj: any) => {
  if (
    obj.fontStyle.includes("bold") &&
    obj.fontStyle.includes("italic") &&
    obj.textDecoration === "underline"
  ) {
    return `<b><u><i>${obj.textValue}</i></u></b>`
  } else if (
    obj.fontStyle.includes("bold") &&
    obj.fontStyle.includes("italic") &&
    obj.textDecoration !== "underline"
  ) {
    return `<b><i>${obj.textValue}</i></b>`
  } else if (
    !obj.fontStyle.includes("bold") &&
    obj.fontStyle.includes("italic") &&
    obj.textDecoration === "underline"
  ) {
    return `<u><i>${obj.textValue}</i></u>`
  } else if (
    obj.fontStyle.includes("bold") &&
    !obj.fontStyle.includes("italic") &&
    obj.textDecoration === "underline"
  ) {
    return `<b><u>${obj.textValue}</u></b>`
  } else if (
    !obj.fontStyle.includes("bold") &&
    !obj.fontStyle.includes("italic") &&
    obj.textDecoration !== "underline"
  ) {
    return `${obj.textValue}`
  } else if (
    obj.fontStyle.includes("bold") &&
    !obj.fontStyle.includes("italic") &&
    obj.textDecoration !== "underline"
  ) {
    return `<b>${obj.textValue}</b>`
  } else if (
    !obj.fontStyle.includes("bold") &&
    obj.fontStyle.includes("italic") &&
    obj.textDecoration !== "underline"
  ) {
    return `<i>${obj.textValue}</i>`
  } else if (
    !obj.fontStyle.includes("bold") &&
    !obj.fontStyle.includes("italic") &&
    obj.textDecoration === "underline"
  ) {
    return `<u>${obj.textValue}</u>`
  }
}

interface iFile {
  name: string
  size: number
  type: string
}

type Props = {
  fontValue: string
  alignValue: string
  color: string
  colorText: string
  bold: boolean
  italic: boolean
  underlined: boolean
  textVisible: boolean
  qrVisible: boolean
  countVisible: boolean
  selectVisible: boolean
  resetFilters: any
  setShowOthers: any
  setShowModal: any
  setSavePreview: any
  savePreview: boolean
  qrDesignName: string
  file: iFile
  setEdit: any
  setQrDesignName: any
  deleteAll: boolean
  setDeleteAll: any
  show: boolean
  setColor: any
  setColorText: any
  setFontValue: any
  setAlignValue: any
  setCountVisible: any
  setShow: any
  updateText: boolean
  setUpdateText: any
  setTextVisible: any
  setQrVisible: any
  setSelectVisible: any
  edit: boolean
}

type TextBlock = {
  x: number
  y: number
  width: number
  height: number
  font_size: number
  font_family: string
  color: string
  background_color: string
  align: string
  content: string | undefined
}

// type QRBlock = {
//   x: number
//   y: number
// } | null

type SelectBlock = {
  x: number
  y: number
  width?: number
  height?: number
  fontSize?: number
  fontFamily?: string
  fill?: string
  color?: string
  align?: string
  content?: string
  background_color?: string
  textEditVisible?: boolean
  isEditable?: boolean
}

type CountBlock = {
  x: number
  y: number
  width?: number
  height?: number
  fontSize?: number
  fontFamily?: string
  fill?: string
  color?: string
  align?: string
  content?: string
  background_color?: string
  textEditVisible?: boolean
  isEditable?: boolean
}

// Add this type near the top of the file
type TextAlignType = "left" | "center" | "right";

const QrCodeCanvasInput = ({
  fontValue,
  alignValue,
  color,
  colorText,
  bold,
  italic,
  underlined,
  textVisible,
  qrVisible,
  countVisible,
  setCountVisible,
  setTextVisible,
  setQrVisible,
  setSelectVisible,
  selectVisible,
  resetFilters,
  setShowOthers,
  setShowModal,
  setSavePreview,
  savePreview,
  qrDesignName,
  setColor,
  setColorText,
  file,
  setEdit,
  setQrDesignName,
  deleteAll,
  setDeleteAll,
  show,
  setFontValue,
  setAlignValue,
  setShow,
  updateText,
  setUpdateText,
  edit,
}: Props) => {
  const dispatch = useAppDispatch()
  const croppedImagePath = useTypeSelector((state) => state.qr.croppedImagePath)
  const { currentEvent, qr_image, qr_design, qr_id, updated_qr_design } =
    useAppSelector((state) => state.events)
  const [guest, setGuest] = useState({
    value: "Mrs Sama Al-Rayez",
    label: "Mrs Sama Al-Rayez",
  })
  const base64ToFile = (base64: any, filename: any) => {
    try {
      const arr = base64?.split(",")
      const mime = arr[0]?.match(/:(.*?);/)[1] // Extract MIME type (image/png or image/jpeg)
      const ext = mime?.split("/")[1] // Get file extension
      const bstr = atob(arr[1]) // Decode base64
      let n = bstr?.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr?.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.${ext}`, { type: mime })
    } catch (err) {
      console.log("err", err)
    }
  }
  const blobToBase64 = async (blobUrl: string): Promise<string> => {
    try {
      const response = await fetch(blobUrl);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });
    } catch (error) {
      console.error("Error converting Blob URL to Base64:", error);
      return "";
    }
  };

  const [fileNewFinal, setFileNewFinal] = useState<File | null>(null);

  useEffect(() => {
    const convertBlobToFile = async () => {
      if (croppedImagePath?.startsWith("blob:")) {
        // Convert Blob URL to Base64 first
        const base64Image = await blobToBase64(croppedImagePath);
        if (base64Image) {
          const file = base64ToFile(base64Image, `image_${Date.now()}`);
          if (file) {
            setFileNewFinal(file);
          }
        }
      } else {
        // If already Base64, convert directly
        const file = base64ToFile(croppedImagePath, `image_${Date.now()}`);
        if (file) {
          setFileNewFinal(file);
        }
      }
    };

    convertBlobToFile();
  }, [croppedImagePath]);


  const [currentQrDesign, setCurrentQrDesign] = useState<any | null>(null)
  const [showSelect, setShowSelect] = useState(false)
  const [showText, setShowText] = useState(false)
  const [textBlock, setTextBlock] = useState<TextBlock[]>([])
  const [qrBlock, setQrBlock] = useState<QRBlock>()
  const [countBlock, setCountBlock] = useState<CountBlock>()
  const [selectBlock, setSelectBlock] = useState<SelectBlock>()
  const [height, setHeight] = useState(36)
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)
  const { deleteImage, setImage, setCroppedImagePath } = useActions()
  const { event_id } = useParams()
  const canvasRef = useRef<any>(null)
  const textNodeRef = useRef<any>(null)
  const counterNodeRef = useRef<any>(null)
  const trRef = useRef<any>(null)
  const inputRef = useRef<any>(null)
  const ref = useRef<SelectInstance>(null)
  const notify = (text: string) => toast.error(text)
  const notifySuccess = (text: string) => toast.success(text)
  const navigate = useNavigate()
  // Add refs for QR and counter
  const qrRef = useRef<Konva.Image>(null);
  const counterRef = useRef<Konva.Text>(null);
  const imageClose = new Image()
  imageClose.src = "/vector/QrDesign/removing.svg"
  imageClose.alt = "Close"
  const imageEdit = new Image()
  imageEdit.src = "/vector/QrDesign/editing.svg"
  imageEdit.alt = "Edit"
  const imageArrow = new Image()
  imageArrow.src = "/vector/QrDesign/down-arrow.svg"
  imageArrow.alt = "Arrow"
  const qrContainerRef = useRef<HTMLDivElement | null>(null)
  const [imageSrc, setImageSrc] = useState<HTMLImageElement | null>(null)
  const [imageHeight, setImageHeight] = useState<number>(0)
  const [imageWidth, setImageWidth] = useState<number>(0)
  const transformerRef = useRef<Konva.Transformer>(null)
  const [imageLoaded, setImageLoaded] = useState(false)
  const selectTextRef = useRef<any>(null) // Ref for the main Text component
  const selectTrRef = useRef<any>(null)
  const warningShown = useRef(false);
  async function isBrave(): Promise<boolean> {
    if (typeof navigator !== "undefined" && "brave" in navigator) {
      try {
        const brave = navigator.brave as { isBrave?: () => Promise<boolean> };
        return await brave.isBrave?.() ?? false;
      } catch {
        return false;
      }
    }
    return navigator.userAgent.includes("Brave") ||
      navigator.userAgent.includes("Brave/");
  }
  async function isShieldsBlocking(): Promise<boolean> {
    try {
      const testUrl = 'https://www.google-analytics.com/analytics.js';
      const response = await fetch(testUrl, {
        mode: 'no-cors',
        cache: 'no-store'
      });
      return false;
    } catch (error) {
      return true;
    }
  }
  useEffect(() => {
    const initializeApp = async () => {
      const isBraveBrowser = await isBrave();
      const isShields = await isShieldsBlocking();

      if (isBraveBrowser && !warningShown.current && isShields) {
        console.log("Brave detected - applying Konva optimizations");
        Konva.hitOnDragEnabled = true;

        toastify.info("Please disable Shields for The Drag Functionality to work", {
          position: "top-right",
          autoClose: 3000,
          toastId: 'brave-warning' // Key fix: prevents duplicates
        });

        warningShown.current = true;
      }

      try {
        posthog.init("phc_FYZhUJR6DHib55TiqeHDOAp31GKwFhyVJ0A4mXyTPKK", {
          api_host: "https://app.posthog.com",
          loaded: (ph) => console.log("PostHog initialized"),
          capture_pageview: false
        });
      } catch (error) {
        console.warn("PostHog initialization failed:", error);
      }
    };

    initializeApp();
  }, []);


  const getImageHeight = (base64: any) => {
    const img = new Image();
    img.onload = () => {
      const maxCanvasWidth = width > 1279 ? 640 : width > 767 ? 408 : 268;
      const maxCanvasHeight = width > 1279 ? 640 : width > 767 ? 408 : 268;

      let scaledWidth = img.width;
      let scaledHeight = img.height;

      if (img.width > maxCanvasWidth || img.height > maxCanvasHeight) {
        const scale = Math.min(
          maxCanvasWidth / img.width,
          maxCanvasHeight / img.height
        );
        scaledWidth = img.width * scale;
        scaledHeight = img.height * scale;
      }

      setImageHeight(scaledHeight);
      setImageWidth(scaledWidth);
      setImageLoaded(true)
    };
    img.src = base64;
  };
  ////qrcode
  const getStyle = () => {
    if (bold && italic) {
      return "italic bold"
    } else if (bold && !italic) {
      return "bold"
    } else if (!bold && italic) {
      return "italic"
    } else {
      return "normal"
    }
  }

  const [newTextObj, setNewTextObj] = useState({
    textEditVisible: false,
    isEditable: false,
    x: 50,
    y: 50,
    color: color,
    fill: colorText,
    textValue: "Add text",
    fontSize: width > 767 ? (width < 1280 ? 24 : 36) : 18,
    fontFamily: fontValue,
    fontStyle: getStyle(),
    width: 100, // Start with a reasonable default width
    height: width > 767 ? (width < 1280 ? 38 : 52) : 31,
    align: alignValue,
    id: 0,
    textDecoration: underlined ? "underline" : "empty string",
  })

  const [countObj, setCountObj] = useState({
    x: 50,
    y: 50,
    textEditVisible: false,
    isEditable: false,
    color: color,
    fill: colorText,
    textValue: "1",
    fontSize: width > 767 ? 18 : 12,
    fontFamily: fontValue,
    fontStyle: getStyle(),
    width: width > 767 ? (width < 1280 ? 41 : 49) : 38,
    height: width > 767 ? 44 : 36,
    align: alignValue,
    textDecoration: underlined ? "underline" : "empty string",
  })

  const [selectObj, setSelectObj] = useState({
    textEditVisible: false,
    isEditable: false,
    x: 50,
    y: 50,
    fill: colorText,
    color: color,
    textValue: guest.value,
    fontSize: width > 767 ? 18 : 12,
    fontFamily: fontValue,
    fontStyle: getStyle(),
    width: width > 767 ? 187 : 132,
    height: width > 767 ? 36 : 38,
    align: alignValue,
    id: 0,
    textDecoration: underlined ? "underline" : "empty string",
  })

  const [qrObj, setQrObj] = useState({
    textEditVisible: false,
    isEditable: false,
    width: width > 1279 ? 187 : width > 767 ? 125 : 72,
    height: width > 1279 ? 187 : width > 767 ? 125 : 72,
    x: 150,
    y: 150,
    color: color,
    fill: colorText,
  })

  const [selectX, setSelectX] = useState({ x: selectObj.x, y: selectObj.y })
  const [textX, setTextX] = useState({ x: newTextObj.x, y: newTextObj.y })

  useEffect(() => {
    if (qrContainerRef.current) {
      const svgElement = qrContainerRef.current.querySelector("svg")
      if (svgElement) {
        const svgString = new XMLSerializer().serializeToString(svgElement)
        const svgBlob = new Blob([svgString], {
          type: "image/svg+xml;charset=utf-8",
        })
        const url = URL.createObjectURL(svgBlob)

        const img = new Image()
        img.onload = () => {
          setImageSrc(img)
          URL.revokeObjectURL(url)
        }
        img.src = url
      }
    }
  }, [color, colorText, qrObj.color, qrObj.fill, qrObj.width, qrObj.height])

  function getOriginalHeight(scaledHeight: number) {
    const baseHeight = width > 1279 ? 640 : width > 767 ? 408 : 268;
    return (scaledHeight * baseHeight) / 100;
  }
  const getOriginalFontSize = (font_size: number) => {
    const denominator = width > 1279 ? 640 : width > 767 ? 408 : 268;
    return (font_size * denominator) / 100;
  };
  const onDelete = () => {
    deleteImage()
    setImage(null)
    setCroppedImagePath(null)
  }
  useEffect(() => {
    const updateWidowSizes = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", updateWidowSizes)
    return () => {
      window.removeEventListener("resize", updateWidowSizes)
    }
  }, [])

  useEffect(() => {
    getImageHeight(croppedImagePath);
    if (!qr_design && !updated_qr_design) return;
    setSavePreview(true);

    const getBlockValues = (block: any, defaultValues = {}) => {
      const bgRect = canvasRef.current.getBoundingClientRect();


      // Convert percentage positions to actual pixel positions
      const xPos = (block.x * imageWidth) / 100;
      const yPos = (block.y * imageHeight) / 100;
      const blockWidth = (block.width * imageWidth) / 100;
      const blockHeight = (block.height * imageHeight) / 100;

      return {
        x: xPos,
        y: yPos,
        color: block.background_color,
        fill: block.color,
        fontSize: block.font_size,
        fontFamily: fontOptions.find(item => item.label === block.font_family)?.value || "Montserrat",
        fontStyle:
          block.bold && block.italic
            ? "italic bold"
            : block.bold
              ? "bold"
              : block.italic
                ? "italic"
                : "normal",
        width: blockWidth,
        height: blockHeight,
        align: block.align,
        textDecoration: block.underline ? "underline" : "none",
        ...defaultValues,
      };
    };

    const activeDesign = updated_qr_design || qr_design?.design;

    if (activeDesign?.guests_amount_block?.display) {
      setCountVisible(true);
      const countValues = getBlockValues(activeDesign.guests_amount_block);
      setCountObj({
        ...countObj,
        ...countValues,
        textEditVisible: false,
        isEditable: false,
        textValue: countObj.textValue
      });
    }

    if (activeDesign?.name_block?.display) {
      setSelectVisible(true);
      const selectValues = getBlockValues(activeDesign.name_block);
      setSelectObj({
        ...selectObj,
        ...selectValues,
        textEditVisible: false,
        isEditable: false,
        textValue: selectObj.textValue,
        id: selectObj.id
      });
    }

    if (activeDesign?.qr_code_block?.display) {
      setQrVisible(true);
      const qrValues = getBlockValues(activeDesign.qr_code_block);
      setQrObj({
        ...qrObj,
        ...qrValues,
        textEditVisible: false,
        isEditable: false,
      });
    }

    if (activeDesign?.text_blocks?.length > 0) {
      const textBlock = activeDesign.text_blocks[0];
      setTextVisible(true);
      const textValues = getBlockValues(textBlock);
      setNewTextObj({
        ...newTextObj,
        ...textValues,
        textValue: textBlock.content.replace(/<[^>]+>/g, ""),
        textEditVisible: false,
        isEditable: false,
      });
    }
  }, [qr_design, updated_qr_design, width, imageWidth, imageHeight]);

  useEffect(() => {
    if (selectVisible && selectObj.textValue) {
      // Only update block on close or save
      const baseWidth = imageWidth || (width > 1279 ? 640 : width > 767 ? 408 : 268);
      const baseHeight = imageHeight || baseWidth;

      setSelectBlock({
        x: (selectObj.x / baseWidth) * 100,
        y: (selectObj.y / baseHeight) * 100,
        width: (selectObj.width / baseWidth) * 100,
        height: (selectObj.height / baseHeight) * 100,
      });
    }
  }, [selectObj.textEditVisible]); // Only trigger on visibility change, not position

  useEffect(() => {
    if (qrVisible) {
      // Only update block on close or save
      const baseWidth = imageWidth || (width > 1279 ? 640 : width > 767 ? 408 : 268);
      const baseHeight = imageHeight || baseWidth;

      setQrBlock({
        x: (qrObj.x / baseWidth) * 100,
        y: (qrObj.y / baseHeight) * 100,
        width: (qrObj.width / baseWidth) * 100,
        height: (qrObj.height / baseHeight) * 100,
      });
    }
  }, [qrObj.textEditVisible]);

  useEffect(() => {
    if (countVisible && countBlock?.x !== undefined && countBlock?.y !== undefined) {
      const baseWidth = imageWidth || (width > 1279 ? 640 : width > 767 ? 408 : 268);
      const baseHeight = imageHeight || baseWidth;

      // Only update if the position has actually changed
      setCountObj(prev => {
        const newX = (countBlock.x * baseWidth) / 100;
        const newY = (countBlock.y * baseHeight) / 100;

        // Only update if position has actually changed
        if (prev.x === newX && prev.y === newY) {
          return prev;
        }

        return {
          ...prev,
          x: newX,
          y: newY,
          width: countBlock.width ? (countBlock.width * baseWidth) / 100 : prev.width,
          height: countBlock.height ? (countBlock.height * baseHeight) / 100 : prev.height
        };
      });
    }
  }, [countBlock?.x, countBlock?.y, countVisible, imageWidth, imageHeight, width]);

  useEffect(() => {
    if (textVisible && newTextObj.textValue) {
      const baseWidth = imageWidth || (width > 1279 ? 640 : width > 767 ? 408 : 268);
      const baseHeight = imageHeight || baseWidth;

      setTextBlock([{
        x: (newTextObj.x / baseWidth) * 100,
        y: (newTextObj.y / baseHeight) * 100,
        width: (newTextObj.width / baseWidth) * 100,
        height: (newTextObj.height / baseHeight) * 100,
        font_size: newTextObj.fontSize,
        font_family: newTextObj.fontFamily,
        color: rgbaToHex(newTextObj.fill),
        background_color: rgbaToHex(newTextObj.color),
        align: newTextObj.align,
        content: getContent(newTextObj),
      }]);
    }
  }, [newTextObj, textVisible]);

  useEffect(() => {
    if (updateText === true) {
      setNewTextObj({
        ...newTextObj,
        x: 50,
        y: 50,
        width: width > 767 ? 200 : 125,
        height: width > 767 ? (width < 1280 ? 38 : 52) : 31,
      })
      setUpdateText(false)
    }
  }, [updateText])
  useEffect(() => {
    if (newTextObj.textEditVisible === true) {
      trRef.current && trRef.current.nodes([textNodeRef.current])
    } else {
      trRef.current && trRef.current.nodes([])
    }
  }, [newTextObj])
  ///newwl;y added
  useEffect(() => {
    if (
      selectObj?.textEditVisible &&
      selectVisible && // Add this check
      !menuIsOpen &&
      selectTextRef?.current && // Ensure the Text node exists
      selectTrRef?.current
    ) {
      selectTrRef.current.nodes([selectTextRef.current])
      selectTrRef.current.getLayer()?.batchDraw()
    } else if (selectTrRef?.current) {
      selectTrRef.current.nodes([])
      selectTrRef.current.getLayer()?.batchDraw() // Redraw after detaching
    }
  }, [selectObj?.textEditVisible, selectVisible, menuIsOpen])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length,
      )
    }
  }, [showText])

  useEffect(() => {
    if (deleteAll === true) {
      setNewTextObj({
        textEditVisible: false,
        isEditable: false,
        x: 50,
        y: 50,
        color: "white",
        fill: "black",
        textValue: "Add text",
        fontSize: width > 767 ? (width < 1280 ? 24 : 36) : 18,
        fontFamily: fontValue,
        fontStyle: getStyle(),
        width: width > 767 ? 200 : 125,
        height: width > 767 ? (width < 1280 ? 38 : 52) : 31,
        align: alignValue,
        id: 0,
        textDecoration: underlined ? "underline" : "empty string",
      })
      setSelectObj({
        textEditVisible: false,
        isEditable: false,
        x: 50,
        y: 50,
        color: "white",
        fill: "black",
        textValue: guest.value,
        fontSize: width > 767 ? 18 : 12,
        fontFamily: fontValue,
        fontStyle: getStyle(),
        width: width > 767 ? 212 : 157,
        height: width > 767 ? 44 : 36,
        align: alignValue,
        id: 0,
        textDecoration: underlined ? "underline" : "empty string",
      })
      setQrObj({
        textEditVisible: false,
        isEditable: false,
        width: width > 767 ? (width < 1280 ? 125 : 187) : 72,
        height: width > 767 ? (width < 1280 ? 125 : 187) : 72,
        x: 150,
        y: 150,
        color: "white",
        fill: "black",
      })
      setCountObj({
        x: 50,
        y: 50,
        textEditVisible: false,
        isEditable: false,
        color: "white",
        fill: "black",
        textValue: "1",
        fontSize: width > 767 ? 18 : 12,
        fontFamily: fontValue,
        fontStyle: getStyle(),
        width: width > 767 ? (width < 1280 ? 41 : 49) : 38,
        height: width > 767 ? 44 : 36,
        align: alignValue,
        textDecoration: underlined ? "underline" : "empty string",
      })
      setCountBlock({ x: 0, y: 0, width: 0, height: 0 })
      setQrBlock({ x: 0, y: 0, width: 0, height: 0 })
      setSelectBlock({ x: 0, y: 0, width: 0, height: 0 })
      setTextBlock([])
    } else {
      trRef.current && trRef.current.nodes([])
    }
  }, [deleteAll])

  const handleTextEdit = (e: any) => {
    const textNode = textNodeRef.current;
    if (!textNode) return;

    const newValue = e.target.value;

    // Temporarily set text to measure dimensions
    textNode.width('auto');
    textNode.text(newValue);

    // Calculate dimensions based on text content
    const fontSize = newTextObj.fontSize;
    const padding = 20;

    // Get text metrics
    const textWidth = textNode.getTextWidth();
    const textHeight = textNode.getTextHeight();

    // Calculate appropriate dimensions
    const minWidth = Math.max(30, textWidth + padding * 2);
    const maxWidth = width > 1279 ? 500 : width > 767 ? 300 : 200;
    const adjustedWidth = Math.min(maxWidth, minWidth);

    // Set width and let text wrap
    textNode.width(adjustedWidth);

    // Calculate height after wrapping
    const wrappedHeight = Math.max(30, textNode.getHeight() + padding);

    setNewTextObj({
      ...newTextObj,
      textValue: newValue,
      width: adjustedWidth,
      height: wrappedHeight,
    });
  };
  useEffect(() => {
    textNodeRef.current && setHeight(textNodeRef.current.height())
    newTextObj.textEditVisible &&
      setNewTextObj({
        ...newTextObj,
        fill: colorText,
        color: color,
        fontFamily: fontValue,
        align: alignValue,
        fontStyle: getStyle(),
        textDecoration: underlined ? "underline" : "empty string",
      })
    countObj.textEditVisible &&
      setCountObj({
        ...countObj,
        fill: colorText,
        color: color,
        fontFamily: fontValue,
        align: alignValue,
        fontStyle: getStyle(),
        textDecoration: underlined ? "underline" : "empty string",
      })
    selectObj.textEditVisible &&
      setSelectObj({
        ...selectObj,
        fill: colorText,
        color: color,
        fontFamily: fontValue,
        align: alignValue,
        fontStyle: getStyle(),
        textDecoration: underlined ? "underline" : "empty string",
        textValue: guest.value,
      })
    qrObj.textEditVisible &&
      setQrObj({
        ...qrObj,
        color: color,
        fill: colorText,
      })
  }, [
    textNodeRef,
    fontValue,
    colorText,
    color,
    alignValue,
    bold,
    italic,
    underlined,
    guest,
    height,
    canvasRef,
  ])

  const handleTextareaKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      setShowText(false)
    }
  }

  const handleCloseTextArea = (e: any) => {
    setNewTextObj({
      textEditVisible: false,
      isEditable: false,
      x: 50,
      y: 50,
      color: "white",
      fill: "black",
      textValue: "Add text",
      fontSize: width > 767 ? (width < 1280 ? 24 : 36) : 18,
      fontFamily: fontValue,
      fontStyle: getStyle(),
      width: 100,
      height: width > 767 ? (width < 1280 ? 38 : 52) : 31,
      align: alignValue,
      id: 0,
      textDecoration: underlined ? "underline" : "empty string",
    });
    setTextVisible(false);
  }
  // handleCloseQR
  const handleCloseQR = (e: any) => {
    setQrObj({
      textEditVisible: false,
      isEditable: false,
      width: width > 1279 ? 187 : width > 767 ? 125 : 72,
      height: width > 1279 ? 187 : width > 767 ? 125 : 72,
      x: 150,
      y: 150,
      color: "white",
      fill: "black",
    })
    setQrVisible(false);
  }

  const handleCloseSelect = (e: any) => {
    setSelectObj({
      textEditVisible: false,
      isEditable: false,
      x: 50,
      y: 50,
      fill: "black",
      color: "white",
      textValue: guest.value,
      fontSize: width > 767 ? 18 : 12,
      fontFamily: fontValue,
      fontStyle: getStyle(),
      width: width > 767 ? 187 : 132,
      height: width > 767 ? 36 : 38,
      align: alignValue,
      id: 0,
      textDecoration: underlined ? "underline" : "empty string",
    })
    setSelectVisible(false);
    setMenuIsOpen(false);
  }

  const handleKeyDown = (e: any) => {
    setCountObj({
      x: 50,
      y: 50,
      textEditVisible: false,
      isEditable: false,
      color: "white",
      fill: "black",
      textValue: "1",
      fontSize: width > 767 ? 18 : 12,
      fontFamily: fontValue,
      fontStyle: getStyle(),
      width: width > 767 ? (width < 1280 ? 41 : 49) : 38,
      height: width > 767 ? 44 : 36,
      align: alignValue,
      textDecoration: underlined ? "underline" : "empty string",
    })
    setCountVisible(false);
  };

  const handleSelectKeyDown = (e: any) => {
    selectObj.isEditable && setGuest(e)
    setSelectObj({
      ...selectObj,
      textValue: guest.value,
    })
    setMenuIsOpen(false)
  }
  const handleQRDblClick = () => {
    if (!savePreview) {
      setShowOthers(false);

      // Reset other elements
      setSelectObj({
        ...selectObj,
        isEditable: false,
        textEditVisible: false,
      });
      setNewTextObj({
        ...newTextObj,
        isEditable: false,
        textEditVisible: false,
      });
      setCountObj({
        ...countObj,
        isEditable: false,
        textEditVisible: false
      });

      // Set QR object as active
      setQrObj({
        ...qrObj,
        isEditable: true,
        textEditVisible: true,
      });

      // Update color controls with current QR colors
      setColor(qrObj.color);
      setColorText(qrObj.fill);
      setShow(true);
    }
  };
  const handleQRResize = (newWidth: number, newHeight: number, newX: number, newY: number) => {
    // First update the pixel values in qrObj
    setQrObj(prev => ({
      ...prev,
      width: newWidth,
      height: newHeight,
      x: newX,
      y: newY
    }));

    // Calculate normalized values (as percentages)
    const normalizedWidth = (newWidth / (imageWidth ?? 600)) * 100;
    const normalizedHeight = (newHeight / (imageHeight ?? 600)) * 100;
    const normalizedX = (newX / (imageWidth ?? 600)) * 100;
    const normalizedY = (newY / (imageHeight ?? 600)) * 100;

    // Update the normalized values in qrBlock
    setQrBlock(prev => ({
      ...prev,
      width: normalizedWidth,
      height: normalizedHeight,
      x: normalizedX,
      y: normalizedY
    }));

    // Regenerate QR code SVG with new dimensions
    if (qrContainerRef.current) {
      const svgElement = qrContainerRef.current.querySelector("svg");
      if (svgElement) {
        svgElement.setAttribute("width", String(newWidth));
        svgElement.setAttribute("height", String(newHeight));
      }
    }
  };
  const handleTextDblClick = (e: any, obj: any, func: any) => {
    setShowOthers(true);
    if (!savePreview) {
      // Reset all elements first
      setNewTextObj({
        ...newTextObj,
        isEditable: false,
        textEditVisible: false,
      });
      setSelectObj({
        ...selectObj,
        isEditable: false,
        textEditVisible: false,
      });
      setQrObj({
        ...qrObj,
        isEditable: false,
        textEditVisible: false,
      });
      setCountObj({
        ...countObj,
        isEditable: false,
        textEditVisible: false,
      });

      // Then set the clicked element as active
      if (obj === countObj) {
        setCountObj({
          ...countObj,
          isEditable: true,
          textEditVisible: true,
        });
        setShow(true);
        setColor(countObj.color);
        setColorText(countObj.fill);
        setFontValue(fontOptions.find((item) => item.label === countObj.fontFamily));
        setAlignValue(alignOptions.find((item) => item.value === countObj.align));
      } else if (obj === newTextObj) {
        setNewTextObj({
          ...newTextObj,
          isEditable: true,
          textEditVisible: true,
        });
        setShow(true);
        setColor(newTextObj.color);
        setColorText(newTextObj.fill);
        setFontValue(fontOptions.find((item) => item.label === newTextObj.fontFamily));
        setAlignValue(alignOptions.find((item) => item.value === newTextObj.align));
      } else if (obj === selectObj) {
        setSelectObj({
          ...selectObj,
          isEditable: true,
          textEditVisible: true,
        });
        setShow(true);
        setColor(selectObj.color);
        setColorText(selectObj.fill);
        setFontValue(fontOptions.find((item) => item.label === selectObj.fontFamily));
        setAlignValue(alignOptions.find((item) => item.value === selectObj.align));
      } else if (obj === qrObj) {
        setQrObj({
          ...qrObj,
          isEditable: true,
          textEditVisible: true,
        });
        setShow(true);
        setColor(qrObj.color);
        setShowOthers(false);
      }

      func({
        ...obj,
        isEditable: true,
        textEditVisible: true,
      });
    }
  };

  const openPreview = () => {
    try {
      setShowOthers(true);
      setSelectObj({ ...selectObj, isEditable: false, textEditVisible: false });
      setNewTextObj({ ...newTextObj, isEditable: false, textEditVisible: false });
      setQrObj({ ...qrObj, isEditable: false, textEditVisible: false });
      setCountObj({ ...countObj, isEditable: false, textEditVisible: false });
      const bgRect = canvasRef.current.getBoundingClientRect();


      const guest = guests.find((item) => item.value === selectObj.textValue);
      const designParams = {
        name: qrDesignName,
        guests_amount_block: {
          display: countVisible,
          x: (countObj.x / bgRect.width) * 100,
          y: (countObj.y / bgRect.height) * 100,
          width: (countObj.width / bgRect.width) * 100,
          height: (countObj.height / bgRect.height) * 100,
          font_size: countObj.fontSize,
          font_family: countObj.fontFamily,
          color: rgbaToHex(countObj.fill),
          background_color: rgbaToHex(countObj.color),
          align: countObj.align,
          bold: countObj.fontStyle.includes("bold"),
          italic: countObj.fontStyle.includes("italic"),
          underline: countObj.textDecoration === "underline",
          content: countObj.textValue,
        },
        name_block: {
          display: selectVisible,
          x: (selectObj.x / bgRect.width) * 100,
          y: (selectObj.y / bgRect.height) * 100,
          width: (selectObj.width / bgRect.width) * 100,
          height: (selectObj.height / bgRect.height) * 100,
          font_size: selectObj.fontSize,
          font_family: selectObj.fontFamily,
          color: rgbaToHex(selectObj.fill),
          background_color: rgbaToHex(selectObj.color),
          align: selectObj.align,
          bold: selectObj.fontStyle.includes("bold"),
          italic: selectObj.fontStyle.includes("italic"),
          underline: selectObj.textDecoration === "underline",
          option: guest?.type || "suffix_full_name",
        },
        qr_code_block: {
          display: qrVisible,
          x: (qrObj.x / bgRect.width) * 100,
          y: (qrObj.y / bgRect.height) * 100,
          width: Math.min(100, (qrObj.width / bgRect.width) * 100, (qrObj.height / bgRect.height) * 100),
          height: Math.min(100, (qrObj.height / bgRect.height) * 100, (qrObj.width / bgRect.width) * 100),
          color: rgbaToHex(qrObj.fill),
          background_color: rgbaToHex(qrObj.color),
        },
        text_blocks: textVisible ? [{
          x: (newTextObj.x / bgRect.width) * 100,
          y: (newTextObj.y / bgRect.height) * 100,
          width: (newTextObj.width / bgRect.width) * 100,
          height: (newTextObj.height / bgRect.height) * 100,
          font_size: newTextObj.fontSize,
          font_family: newTextObj.fontFamily,
          color: rgbaToHex(newTextObj.fill),
          background_color: rgbaToHex(newTextObj.color),
          align: newTextObj.align,
          content: getContent(newTextObj),
          text_value: newTextObj.textValue, // Add this line to send actual text
        }] : [],
      };

      const designId = currentEvent?.qr_code_design_id || qr_id;

      if (designId) {
        dispatch(updateQrDesign({
          id: designId,
          params: designParams
        })).then((result: any) => {
          if (result.error) {
            result.payload.name && notify(result.payload.name[0]);
          } else {
            const requestBodyImage = {
              qrCodeDesignId: result?.payload?.id,
              image: fileNewFinal,
            };
            api.events?.uploadImageToQrCode(requestBodyImage);
            setSavePreview(true);
            setCurrentQrDesign(result?.payload?.id);
            const { qr_code_design_id, invitation_show_qr_code, ...eventWithoutQrCode } = currentEvent || {};
          }
          const newEvent: any = currentEvent
          const {
            qr_code_design_id,
            invitation_show_qr_code,
            header,
            footer,
            dress_code,
            start_datetime,
            ...eventWithoutQrCode
          } = newEvent || {}
          const requestBody = {
            qr_code_design_id: result?.payload?.id,
            invitation_show_qr_code: true,
            ...eventWithoutQrCode,
          }
          const testEventUpdated = api.events?.createEventTest(requestBody)

        });
      } else {
        dispatch(sendQrDesign(designParams)).then((result: any) => {
          if (result.error) {
            result.payload.name && notify(result.payload.name[0]);
          } else {
            const requestBodyImage = {
              qrCodeDesignId: result?.payload?.id,
              image: fileNewFinal,
            };
            api.events?.uploadImageToQrCode(requestBodyImage);
            setCurrentQrDesign(result?.payload?.id);
            setSavePreview(true);
            const { qr_code_design_id, invitation_show_qr_code, ...eventWithoutQrCode } = currentEvent || {};
          }
          const newEvent: any = currentEvent
          const {
            qr_code_design_id,
            invitation_show_qr_code,
            header,
            footer,
            dress_code,
            start_datetime,
            ...eventWithoutQrCode
          } = newEvent || {}
          const requestBody = {
            qr_code_design_id: result?.payload?.id,
            invitation_show_qr_code: true,
            ...eventWithoutQrCode,
          }
          const testEventUpdated = api.events?.createEventTest(requestBody)

        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const onCompleted = async () => {
    try {
      const response: Response =
        croppedImagePath !== null
          ? await fetch(croppedImagePath)
          : await fetch("")
      const blob = await response.blob()
      const formData = new FormData()
      formData.append("image", blob, "filename.ext")

      dispatch(setLoading())
      notifySuccess("Design successfully created")
      onDelete()
      deleteImage()
      setImage(null)
      setCroppedImagePath(null)
      setQrDesignName("")
      setSavePreview(false)
      setTimeout(() => {
        navigate(`/event-edit/${event_id}`)
      }, 1500)

    } catch (error) {
      console.error("Error while sending file:", error)
    }
  }
  const onTextShow = (e: any) => {
    if (newTextObj.textEditVisible) {
      setShowText(true)
      const absPos = e.target.getAbsolutePosition()
      setTextX({
        ...textX,
        x: absPos.x > 0 ? absPos.x : 0,
        y: absPos.y > 0 ? absPos.y : 0,
      })
      inputRef.current && inputRef.current.focus()
    }
  }
  const onTransform = (e: Konva.KonvaEventObject<Event>) => {
    const node = textNodeRef.current;
    if (!node) return;

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    if (scaleX !== 1 || scaleY !== 1) {
      const scale = scaleX !== 1 ? scaleX : scaleY;
      const originalWidth = newTextObj.width;
      const originalHeight = newTextObj.height;
      const originalFontSize = newTextObj.fontSize;

      const newWidth = Math.max(30, originalWidth * scale);
      const newHeight = Math.max(20, originalHeight * scale);
      const fontScalingMultiplier = 1.0;
      const newFontSize = Math.max(8, originalFontSize * scale * fontScalingMultiplier);

      node.scaleX(1);
      node.scaleY(1);
      node.width(newWidth);
      node.height(newHeight);

      setNewTextObj(prev => ({
        ...prev,
        width: newWidth,
        height: newHeight,
        fontSize: newFontSize
      }));

    } else {
      const newWidth = node.width();
      const newHeight = node.height();

      setNewTextObj(prev => ({
        ...prev,
        width: newWidth,
        height: newHeight
      }));
    }

    node.getLayer()?.batchDraw();
  };

  const handleSelectTransform = (e: Konva.KonvaEventObject<Event>) => {
    const node = selectTextRef.current;
    if (!node) return;

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    const originalWidth = selectObj.width;
    const originalHeight = selectObj.height;
    const originalFontSize = selectObj.fontSize;

    const scale = scaleX !== 1 ? scaleX : scaleY;

    const newWidth = originalWidth * scale;
    const newHeight = originalHeight * scale;

    const fontScalingMultiplier = 1.0;
    const newFontSize = Math.max(8, originalFontSize * scale * fontScalingMultiplier);

    node.scaleX(1);
    node.scaleY(1);

    node.width(newWidth);
    node.height(newHeight);

    // Update state
    setSelectObj((prev) => ({
      ...prev,
      width: newWidth,
      height: newHeight,
      fontSize: newFontSize,
    }));
    // Redraw
    node.getLayer()?.batchDraw();
  };
  const onEdit = () => {
    setSavePreview(false)
    setEdit(true)
  }
  const toggleMenuIsOpen = (e: any) => {
    const absPos = e.target.getAbsolutePosition()
    setSelectX({
      ...selectX,
      x: absPos.x > 0 ? absPos.x : 0,
      y: absPos.y > 0 ? absPos.y : 0,
    })
    setMenuIsOpen(menuIsOpen === true ? false : true)
  }
  React.useEffect(() => {
    getImageHeight(croppedImagePath)
  }, [croppedImagePath])
  console.log("the new text obj", newTextObj.x, newTextObj.y)
  console.log("the select obj", selectObj.x, selectObj.y)
  console.log("the count obj", countObj.x, countObj.y, countObj.width, countObj.height)
  console.log("the qr obj", qrObj.x, qrObj.y)
  console.log("the new text block", textBlock[0]?.x, textBlock[0]?.y)
  console.log("the select block", selectBlock?.x, selectBlock?.y)
  console.log("the count block", countBlock?.x, countBlock?.y)
  console.log("the qr block", qrBlock?.x, qrBlock?.y)
  console.log("the image height", imageHeight)
  console.log("the image width", imageWidth)
  console.log("thE WIDTH", width)
  console.log("the new text obj", newTextObj.textValue)

  const [isDragging, setIsDragging] = useState({
    text: false,
    counter: false,
    select: false,
    qr: false
  });

  const handleTextDragStart = () => {
    setIsDragging(prev => ({ ...prev, text: true }));
  };

  // const handleTextDragMove = (e: Konva.KonvaEventObject<DragEvent>) => {
  //   if (!textNodeRef.current) return;
  //   const bgRect = canvasRef.current.getBoundingClientRect();
  //   const pos = textNodeRef.current.getAbsolutePosition();
  //   const blockWidth = textNodeRef.current.getWidth();
  //   const blockHeight = textNodeRef.current.getHeight();
  //   const x = Math.max(0, Math.min(pos.x, bgRect.width - blockWidth));
  //   const y = Math.max(0, Math.min(pos.y, bgRect.height - blockHeight));

  //   requestAnimationFrame(() => {

  //   });
  // };

  const handleTextDragEnd = (e: any) => {
    setIsDragging(prev => ({ ...prev, text: false }));
    const bgRect = canvasRef.current.getBoundingClientRect();
    const pos = textNodeRef.current.getAbsolutePosition();
    const blockWidth = textNodeRef.current.getWidth();
    const blockHeight = textNodeRef.current.getHeight();
    const x = Math.max(0, Math.min(pos.x, bgRect.width - blockWidth));
    const y = Math.max(0, Math.min(pos.y, bgRect.height - blockHeight));
    setNewTextObj(prev => ({
      ...prev,
      x,
      y
    }))

    setTextBlock([{
      ...textBlock[0],
      x: (pos.x / bgRect.width) * 100,
      y: (pos.y / bgRect.height) * 100,
      width: (newTextObj.width / bgRect.width) * 100,
      height: (newTextObj.height / bgRect.height) * 100,
      font_size: newTextObj.fontSize,
      font_family: newTextObj.fontFamily,
      color: rgbaToHex(newTextObj.fill),
      background_color: rgbaToHex(newTextObj.color),
      align: newTextObj.align,
      content: getContent(newTextObj),
    }]);
  };

  const handleSelectDragEnd = (e: any) => {
    setIsDragging(prev => ({ ...prev, select: false }));
    const bgRect = canvasRef.current.getBoundingClientRect();
    const pos = selectTextRef.current.getAbsolutePosition();
    const blockWidth = selectTextRef.current.getWidth();
    const blockHeight = selectTextRef.current.getHeight();
    const x = Math.max(0, Math.min(pos.x, bgRect.width - blockWidth));
    const y = Math.max(0, Math.min(pos.y, bgRect.height - blockHeight));
    setSelectObj(prev => ({
      ...prev,
      x,
      y
    }))

    setSelectBlock({
      ...selectBlock,
      x: (pos.x / bgRect.width) * 100,
      y: (pos.y / bgRect.height) * 100,
      width: (selectObj.width / bgRect.width) * 100,
      height: (selectObj.height / bgRect.height) * 100,
    });
  };

  const getDragBoundFunc = (nodeRef: React.RefObject<any>) => {
    return (pos: { x: number; y: number }) => {
      if (!nodeRef.current || !canvasRef.current) return pos;

      const node = nodeRef.current;
      const bgRect = canvasRef.current.getBoundingClientRect();

      const width = node.width() * node.scaleX();
      const height = node.height() * node.scaleY();

      const rotation = node.rotation() || 0;
      const rad = (rotation * Math.PI) / 180;
      const effectiveWidth = Math.abs(width * Math.cos(rad)) + Math.abs(height * Math.sin(rad));
      const effectiveHeight = Math.abs(height * Math.cos(rad)) + Math.abs(width * Math.sin(rad));

      const padding = 2;
      const minX = padding;
      const maxX = Math.max(minX, bgRect.width - effectiveWidth - padding);
      const minY = padding;
      const maxY = Math.max(minY, bgRect.height - effectiveHeight - padding);

      return {
        x: Math.max(minX, Math.min(pos.x, maxX)),
        y: Math.max(minY, Math.min(pos.y, maxY))
      };
    };
  };

  const rgbaToHex = (color: string) => {
    if (!color || color === 'transparent' || color === 'none') {
      return 'transparent';
    }
    if (color.startsWith('#')) {
      return color;
    }

    const tempElement = document.createElement('div');
    tempElement.style.color = color;
    document.body.appendChild(tempElement);

    const computedColor = window.getComputedStyle(tempElement).color;
    document.body.removeChild(tempElement);

    const rgbaMatch = computedColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)$/);
    if (!rgbaMatch) return color;

    const r = parseInt(rgbaMatch[1]);
    const g = parseInt(rgbaMatch[2]);
    const b = parseInt(rgbaMatch[3]);
    const a = rgbaMatch[4] !== undefined ? parseFloat(rgbaMatch[4]) : 1;

    if (a === 0) {
      return 'transparent';
    }

    const toHex = (n: number) => {
      const hex = n.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  useEffect(() => {
    if (qrObj.textEditVisible) {
      setQrObj(prev => ({
        ...prev,
        color: color,
        fill: colorText,
      }));
    }
  }, [color, colorText]);
  const handleQrDragStart = () => {
    setIsDragging(prev => ({ ...prev, qr: true }));
  };

  const handleQrDragEnd = (e: any) => {

    setIsDragging(prev => ({ ...prev, qr: false }));

    if (!qrRef.current) return;

    const bgRect = canvasRef.current.getBoundingClientRect();
    const pos = qrRef.current.getAbsolutePosition();
    const blockWidth = qrRef.current.getWidth();
    const blockHeight = qrRef.current.getHeight();

    // Calculate bounds
    const x = Math.max(0, Math.min(pos.x, bgRect.width - blockWidth));
    const y = Math.max(0, Math.min(pos.y, bgRect.height - blockHeight));

    // Update state directly with event position
    setQrObj(prev => ({
      ...prev,
      x,
      y
    }));

    // Update the normalized block position
    setQrBlock(prev => ({
      ...prev,
      x: (x / bgRect.width) * 100,
      y: (y / bgRect.height) * 100,
      width: (blockWidth / bgRect.width) * 100,
      height: (blockHeight / bgRect.height) * 100
    }));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginTop: "24px",
        height: "auto",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ToastContainer
        style={{ width: "90%", maxWidth: "400px", fontSize: "18px" }}
      />
      <div className="mb-3 mt-2 d-flex justify-content-center">
        {!savePreview ? (
          <div
            style={{ display: "flex", justifyContent: "center", gap: "16px" }}
          >
            <span
              className="btn btn-simple"
              style={{ padding: "0px 28px" }}
              onClick={() => {
                setShowModal(true)
              }}
            >
              Cancel
            </span>
            <button
              type="submit"
              className="btn btn-dark"
              style={{ padding: "0px 28px" }}
              onClick={openPreview}
            >
              Save
            </button>
          </div>
        ) : (
          <div
            style={{ display: "flex", justifyContent: "center", gap: "16px" }}
          >
            <span
              className="btn btn-simple"
              style={{ padding: "0px 28px" }}
              onClick={onEdit}
            >
              Edit Design
            </span>
            <button
              type="submit"
              className="btn btn-dark"
              style={{ padding: "0px 28px" }}
              onClick={onCompleted}
            >
              Complete
            </button>
          </div>
        )}
      </div>
      <div
        style={{
          height: `${imageHeight || (width > 1279 ? 640 : width > 767 ? 408 : 268)}px`,
          minHeight: `${imageHeight}px`,
          width: "100%",
          maxWidth: `${imageWidth || (width > 1279 ? 640 : width > 767 ? 408 : 268)}px`,
          margin: "0 auto",
          marginBottom: "24px",
          position: "relative",
          gap: window.innerWidth < 992 ? "24px" : "0",
        }}
      >
        {croppedImagePath !== null && croppedImagePath !== undefined && (
          <div
            style={{
              height: `${imageHeight || (width > 1279 ? 640 : width > 767 ? 408 : 268)}px`,
              width: `${imageWidth || (width > 1279 ? 640 : width > 767 ? 408 : 268)}px`,
              maxWidth: "100%",
              zIndex: 1,
              left: "50%",
              transform: "translateX(-50%)",
              position: "absolute",
            }}
          >
            <img
              style={{
                width: "100%",
                maxHeight: "640px",
                border: "1px solid gray",
              }}
              src={croppedImagePath}
              ref={canvasRef}
            />
          </div>
        )}
        {imageLoaded ?
          <Stage
            width={imageWidth || (width > 1279 ? 640 : width > 767 ? 408 : 268)}
            height={imageHeight || (width > 1279 ? 640 : width > 767 ? 408 : 268)}
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 2,
              maxWidth: `${width > 1279 ? "640px" : width > 767 ? "408px" : "268px"
                }`,
              width: "100%",

              height: `${width > 1279 ? "640px" : width > 767 ? "408px" : "268px"
                }`,
            }}
          >
            {textVisible && (
              <Layer
                opacity={newTextObj.textEditVisible && show === false ? 0 : 1}
              >
                <URLImage
                  opacity={newTextObj.textEditVisible ? (showText ? 0 : 1) : 0}
                  src={imageClose}
                  x={newTextObj.x}
                  y={newTextObj.y - 27}
                  funcClick={handleCloseTextArea}
                  width={22}
                  height={22}
                />
                <Text
                  draggable={newTextObj.textEditVisible ? (showText ? false : true) : false}
                  // onDragMove={handleTextDragMove}
                  onDragEnd={handleTextDragEnd}
                  fontFamily={newTextObj.fontFamily}
                  fontStyle={newTextObj.fontStyle}
                  fontSize={newTextObj.fontSize}
                  textDecoration={newTextObj.textDecoration}
                  align={newTextObj.align}
                  padding={5}
                  verticalAlign="middle"
                  height={newTextObj.textEditVisible ? undefined : newTextObj.height}
                  lineHeight={1.2}
                  ref={textNodeRef}
                  y={newTextObj.y}
                  x={newTextObj.x}
                  fill={newTextObj.fill}
                  text={newTextObj.textValue}
                  opacity={showText ? 0 : 1}
                  wrap="word"
                  width={newTextObj.width}
                  dragBoundFunc={getDragBoundFunc(textNodeRef)}
                  onClick={onTextShow}
                  onTap={onTextShow}
                  onTransform={onTransform}
                  onDblTap={(e) =>
                    handleTextDblClick(e, newTextObj, setNewTextObj)
                  }
                  onDblClick={(e) =>
                    handleTextDblClick(e, newTextObj, setNewTextObj)
                  }
                  sceneFunc={(context, shape) => {
                    const radius = 4
                    context.fillStyle = newTextObj.color
                    context.beginPath()
                    context.moveTo(0 + radius, 0)
                    context.lineTo(0 + shape.width() - radius, 0)
                    context.arcTo(
                      0 + shape.width(),
                      0,
                      0 + shape.width(),
                      0 + radius,
                      radius,
                    )
                    context.lineTo(0 + shape.width(), 0 + shape.height() - radius)
                    context.arcTo(
                      0 + shape.width(),
                      0 + shape.height(),
                      0 + shape.width() - radius,
                      0 + shape.height(),
                      radius,
                    )
                    context.lineTo(0 + radius, 0 + shape.height())
                    context.arcTo(
                      0,
                      0 + shape.height(),
                      0,
                      0 + shape.height() - radius,
                      radius,
                    )
                    context.lineTo(0, 0 + radius)
                    context.arcTo(0, 0, 0 + radius, 0, radius)
                    context.closePath()
                    context.fill()
                      ; (shape as Konva.Text)._sceneFunc(context)
                  }}
                />
                <URLImage
                  opacity={newTextObj.textEditVisible ? (showText ? 0 : 1) : 0}
                  src={imageEdit}
                  x={newTextObj.x + newTextObj.width - 21}
                  y={newTextObj.y + newTextObj.height + 5}
                  width={22}
                  height={22}
                  funcClick={onTransform}
                />

                <Transformer
                  opacity={showText ? 0 : 1}
                  ref={trRef}
                  borderStroke="#6D6D6E"
                  anchorStroke="#6D6D6E"
                  rotateEnabled={false} // Keep rotation disabled if not needed
                  enabledAnchors={[
                    "middle-left",
                    "middle-right",
                    "top-center", // Enables vertical scaling
                    "bottom-center", // Enables vertical scaling
                  ]} // Enable all resize handles
                  boundBoxFunc={(oldBox, newBox) => {
                    // Enforce minimum size
                    newBox.width = Math.max(30, newBox.width)
                    newBox.height = Math.max(20, newBox.height) // Minimum height
                    return newBox
                  }}
                />
              </Layer>
            )}
            {qrVisible && (
              <QRLayer
                qrObj={qrObj}
                width={22}
                imageSrc={imageSrc}
                imageClose={imageClose}
                imageEdit={imageEdit}
                show={qrVisible}
                handleCloseQR={handleCloseQR}
                handleQrResize={handleQRResize}
                handleQRDblClick={handleQRDblClick}
                canvasRef={canvasRef}
                // handleQrDragStart={handleQrDragStart}
                handleQrDragEnd={handleQrDragEnd}
                qrRef={qrRef}
              />
            )}
            {countVisible && (
              <CounterLayer
                countObj={countObj}
                setCountBlock={setCountBlock}
                handleKeyDown={handleKeyDown}
                handleTextDblClick={handleTextDblClick}
                imageClose={imageClose}
                imageEdit={imageEdit}
                setCountObj={setCountObj}
                show={show}
                width={countObj.width}
                height={countObj.height}
                canvasArea={canvasRef.current}
              />
            )}
            {selectVisible && (
              <Layer
                opacity={selectObj.textEditVisible && show === false ? 0 : 1}
              // onDragStart={() => setIsDragging(prev => ({ ...prev, select: true }))}
              >
                <URLImage
                  opacity={selectObj.textEditVisible ? 1 : 0}
                  src={imageClose}
                  x={selectObj.x}
                  y={selectObj.y - 28}
                  funcClick={handleCloseSelect}
                  width={22}
                  height={22}
                />
                <Text
                  draggable={!showSelect ? selectObj.textEditVisible ? !menuIsOpen ? true : false : false : false}

                  onDragEnd={handleSelectDragEnd}
                  dragBoundFunc={getDragBoundFunc(selectTextRef)}
                  ref={selectTextRef} // Attach ref for Transformer
                  fontFamily={selectObj.fontFamily}
                  fontStyle={selectObj.fontStyle}
                  fontSize={selectObj.fontSize}
                  textDecoration={selectObj.textDecoration}
                  opacity={selectObj.textEditVisible ? 1 : 1}
                  align={selectObj.align}
                  verticalAlign="middle"
                  y={selectObj.y}
                  x={selectObj.x}
                  fill={selectObj.fill}
                  text={selectObj.textValue}
                  color={selectObj.color}
                  ellipsis={true}
                  wrap="word"
                  width={selectObj.width}
                  height={selectObj.height}
                  onTransform={handleSelectTransform}
                  perfectDrawEnabled={false}
                  shadowForStrokeEnabled={false}
                  onDblClick={(e) =>
                    handleTextDblClick(e, selectObj, setSelectObj)
                  }
                  onDblTap={(e) => handleTextDblClick(e, selectObj, setSelectObj)}
                  padding={10}
                  sceneFunc={(context, shape) => {
                    const radius = 4
                    context.fillStyle = selectObj.color
                    context.beginPath()
                    context.moveTo(0 + radius, 0)
                    context.arcTo(
                      0 + shape.width(),
                      0,
                      0 + shape.width(),
                      0 + shape.height(),
                      0,
                    )
                    context.arcTo(
                      0 + shape.width(),
                      0 + shape.height(),
                      0,
                      0 + shape.height(),
                      0,
                    )
                    context.arcTo(0, 0 + shape.height(), 0, 0, radius)
                    context.arcTo(0, 0, 0 + radius, 0, radius)
                    context.closePath()
                    context.fill()
                      ; (shape as Konva.Text)._sceneFunc(context)
                  }}
                />

                <URLImage
                  opacity={selectObj.textEditVisible ? 1 : 0}
                  src={imageArrow}
                  x={selectObj.x + selectObj.width - 18}
                  y={width > 768 ? selectObj.y + 12 : selectObj.y + 8}
                  funcClick={toggleMenuIsOpen}
                  width={16}
                  height={16}
                />
                <Transformer
                  ref={selectTrRef}
                  opacity={menuIsOpen ? 0 : 1}
                  borderStroke="#6D6D6E"
                  anchorStroke="#6D6D6E"
                  rotateEnabled={false}
                  enabledAnchors={[
                    "middle-left",
                    "middle-right",
                    "top-center",
                    "bottom-center"
                  ]}
                  boundBoxFunc={(oldBox, newBox) => {
                    if (Math.abs(newBox.width - oldBox.width) > Math.abs(newBox.height - oldBox.height)) {
                      newBox.height = oldBox.height;
                    } else {
                      newBox.width = oldBox.width;
                    }

                    newBox.width = Math.max(50, newBox.width);
                    newBox.height = Math.max(20, newBox.height);
                    return newBox;
                  }}
                />
              </Layer>
            )}
          </Stage> : <Preloader />
        }
        <div ref={qrContainerRef} style={{ display: "none" }}>
          <QRCodeSVG
            value="https://app2.mazoom.sa"
            size={qrObj.height}
            bgColor={qrObj.color}
            fgColor={qrObj.fill}
            level="L"
            includeMargin={true}
          />
        </div>
        {showText && (
          <textarea
            className="canvas__textaria"
            value={newTextObj.textValue}
            ref={inputRef}
            style={{
              display: newTextObj.textEditVisible ? "block" : "none",
              position: "relative",
              zIndex: 3,
              outline: "none",
              border: "0px",
              padding: "10px",
              margin: "0px",
              top: textX.y + "px",
              left: textX.x + "px",
              fontSize: newTextObj.fontSize + "px",
              resize: "both", // Allow both vertical and horizontal resizing
              minWidth: "30px",
              maxWidth: `${width > 1279 ? 500 : width > 767 ? 300 : 200}px`,
              minHeight: "30px",
              background: newTextObj.color,
              width: newTextObj.width + "px",
              height: newTextObj.height + "px",
              fontFamily: newTextObj.fontFamily,
              fontStyle: newTextObj.fontStyle.indexOf("italic") !== -1 ? "italic" : "normal",
              fontWeight: newTextObj.fontStyle.indexOf("bold") !== -1 ? "bold" : "normal",
              textDecoration: newTextObj.textDecoration === "underline" ? "underline" : "none",
              color: newTextObj.fill,
              lineHeight: "1.2",
              textUnderlineOffset: "8px",
              textDecorationThickness: "3px",
              textAlign: (newTextObj.align || 'left') as TextAlignType,
              overflow: "hidden",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word"
            }}
            onChange={(e) => handleTextEdit(e)}
            onBlur={() => setShowText(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                setShowText(false);
              }
            }}
          />
        )}
        <div >
          {menuIsOpen && (
            <Select
              defaultValue={guests[0]}
              options={guests}
              value={guest}
              ref={ref}
              menuIsOpen={menuIsOpen}
              onChange={(e) => handleSelectKeyDown(e)}
              isSearchable={false}
              className="canvas__select"
              styles={{
                container: () => ({
                  display: selectObj.textEditVisible ? "block" : "none",
                  position: "relative",
                  opacity: selectObj.textEditVisible && show === false ? 0 : 1,
                  top:
                    Number(selectX.y) -
                    selectObj.height +
                    (width > 767 ? 28 : 20) +
                    40 +
                    "px",
                  left: Number(selectX.x) - selectObj.width + 25 + "px",
                  width: width > 767 ? "212px" : "157px",
                  zIndex: 3,
                  color: "black",
                }),
                singleValue: () => ({
                  color: "black",
                }),
                control: () => ({
                  width: width > 767 ? "212px" : "157px",
                  display: "none",
                  fontSize: selectObj.fontSize,
                  fontFamily: selectObj.fontFamily,
                  color: "black",
                  border: 0,
                  height: width > 767 ? "44px" : "36px",
                  borderRadius: "4px",
                  zIndex: 100,
                }),
                indicatorSeparator: () => ({
                  display: "none",
                }),
                dropdownIndicator: (base: any, state) => ({
                  ...base,
                  transform: state.selectProps.menuIsOpen
                    ? "rotate(180deg)"
                    : null,
                }),
                option: () => ({
                  display: "flex",
                  alignItems: "center",
                  padding: 10,
                  borderBottom: "1px solid #F2F4F7",
                  cursor: "pointer",
                  fontSize: width > 767 ? 14 : 12,
                  zIndex: 100,
                  width: width > 767 ? "212px" : "157px",
                }),
                menuList: () => ({
                  width: width > 767 ? "212px" : "157px",
                  maxWidth: width > 767 ? "212px" : "157px",
                  opacity: selectObj.textEditVisible && show === false ? 0 : 1,
                  padding: 0,
                  zIndex: 100,
                }),
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default QrCodeCanvasInput
