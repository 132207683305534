import { Dropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { IconFilter } from "../utils/Icons/CustomIcons"
import { useEffect, useState } from "react"

type Props = {
  setFilter: any
  setCheckedFilter: any
  checkedFilter: string
  setCheckedReminder: any
  checkedReminder: string
  filter: {
    sort: string
    order: string
    searchValue: string
    reminderNumber: number | string
    statusNumber: number | string
    id: number
  }
}

const GuestsFilter = ({
  filter,
  setFilter,
  setCheckedFilter,
  checkedFilter,
  setCheckedReminder,
  checkedReminder,
}: Props) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState<any>("0,1,2,5,6")
  const [number, setNumber] = useState<any>("")
  const onCheck = (name: any) => {
    setNumber("")
    setCheckedReminder("")
    if (checkedFilter === name) {
      setCheckedFilter("")
      setStatus("0,1,2,5,6")
      setFilter({ ...filter, statusNumber: status })
    } else {
      setCheckedFilter(name)
      // new changes
      // switch (name) {
      //   case "ignore":
      //     setStatus("0")
      //     break
      //   case "decline":
      //     setStatus("2")
      //     break
      //   case "visited":
      //     setStatus("5")
      //     break
      //   case "failed":
      //     setStatus("6")
      //     break
      //   case "going":
      //     setStatus("1")
      //     break
      //   case "scanned":
      //     setStatus("4")
      //     break
      // }
      switch (name) {
        case "scanned":
          setStatus("4")
          break
        case "failed":
          setStatus("6")
          break
        case "going":
          setStatus("1")
          break
        case "decline":
          setStatus("2")
          break
        case "ignore":
          setStatus("0")
          break

        case "all":
          setStatus("10")
          break
        case "later":
          setStatus("3")
          break
        case "visited":
          setStatus("5")
          break
        case "not_paid":
          setStatus("7")
          break
        case "delivered":
          setStatus("8")
          break
        case "read":
          setStatus("9")
          break
        case "qr_code_delivered":
          setStatus("11")
          break
        case "qr_code_not_delivered":
          setStatus("12")
          break
        case "unsubscribed":
          setStatus("13")
          break
        case "blocked":
          setStatus("14")
          break
      }
      setFilter({ ...filter, statusNumber: status })
    }
  }

  const onCheckReminder = (name: any) => {
    setStatus("0,1,2,5,6")
    setCheckedFilter("")
    if (checkedReminder === name) {
      setCheckedReminder("")
      setNumber("")
      setFilter({ ...filter, reminderNumber: number })
    } else {
      setCheckedReminder(name)
      switch (name) {
        case "No":
          setNumber(0)
          break
        case "Yes":
          setNumber(1)
          break
      }
      setFilter({ ...filter, reminderNumber: number })
    }
  }

  useEffect(() => {
    setFilter({ ...filter, statusNumber: status, reminderNumber: number })
  }, [number, status])

  return (
    <Dropdown
      className={
        "dropdown-block dropdown--not-arrow dropdown-filter thanks-filter"
      }
    >
      <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent thanks-page__btn">
        <IconFilter />
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ width: "350px" }}>
        <span className="dropdown-filter__title">
          {t("thank.filter.status.title")}
        </span>
        {/* <div className="check-list">
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-ignore"
              checked={checkedFilter === "ignore"}
              onChange={() => onCheck("ignore")}
            />
            <label htmlFor="filter-ignore">
              {t("thank.filter.status.ignore")}
            </label>
          </div>
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-going"
              checked={checkedFilter === "going"}
              onChange={() => onCheck("going")}
            />
            <label htmlFor="filter-going">
              {t("thank.filter.status.going")}
            </label>
          </div>
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-decline"
              checked={checkedFilter === "decline"}
              onChange={() => onCheck("decline")}
            />
            <label htmlFor="filter-decline">
              {t("thank.filter.status.decline")}
            </label>
          </div>
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-failed"
              checked={checkedFilter === "failed"}
              onChange={() => onCheck("failed")}
            />
            <label htmlFor="filter-failed">
              {t("thank.filter.status.failed")}
            </label>
          </div>
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-visited"
              checked={checkedFilter === "visited"}
              onChange={() => onCheck("visited")}
            />
            <label htmlFor="filter-visited">
              {t("thank.filter.status.visited")}
            </label>
          </div>
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-scanned"
              checked={checkedFilter === "scanned"}
              onChange={() => onCheck("scanned")}
            />
            <label htmlFor="filter-scanned">
              {t("thank.filter.status.scanned")}
            </label>
          </div>
        </div> */}

        <div
          // className="check-list"
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "30px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-all"
                checked={checkedFilter === "all"}
                onChange={() => onCheck("all")}
              />
              <label htmlFor="filter-all">{t("event.filter.status.all")}</label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-scanned"
                checked={checkedFilter === "scanned"}
                onChange={() => onCheck("scanned")}
              />
              <label htmlFor="filter-scanned">
                {t("event.filter.status.scanned")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-failed"
                checked={checkedFilter === "failed"}
                onChange={() => onCheck("failed")}
              />
              <label htmlFor="filter-failed">
                {t("event.filter.status.failed")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-going"
                checked={checkedFilter === "going"}
                onChange={() => onCheck("going")}
              />
              <label htmlFor="filter-going">
                {t("event.filter.status.going")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-decline"
                checked={checkedFilter === "decline"}
                onChange={() => onCheck("decline")}
              />
              <label htmlFor="filter-decline">
                {t("event.filter.status.decline")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-ignore"
                checked={checkedFilter === "ignore"}
                onChange={() => onCheck("ignore")}
              />
              <label htmlFor="filter-ignore">
                {t("event.filter.status.ignore")}
              </label>
            </div>

            <div className="check-item">
              <input
                type="checkbox"
                id="filter-later"
                checked={checkedFilter === "later"}
                onChange={() => onCheck("later")}
              />
              <label htmlFor="filter-later">
                {t("event.filter.status.later")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-visited"
                checked={checkedFilter === "visited"}
                onChange={() => onCheck("visited")}
              />
              <label htmlFor="filter-visited">
                {t("event.filter.status.visited")}
              </label>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-not_paid"
                checked={checkedFilter === "not_paid"}
                onChange={() => onCheck("not_paid")}
              />
              <label htmlFor="filter-not_paid">
                {t("event.filter.status.not_paid")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-delivered"
                checked={checkedFilter === "delivered"}
                onChange={() => onCheck("delivered")}
              />
              <label htmlFor="filter-delivered">
                {t("event.filter.status.delivered")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-read"
                checked={checkedFilter === "read"}
                onChange={() => onCheck("read")}
              />
              <label htmlFor="filter-read">
                {t("event.filter.status.read")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-qr_code_delivered"
                checked={checkedFilter === "qr_code_delivered"}
                onChange={() => onCheck("qr_code_delivered")}
              />
              <label htmlFor="filter-qr_code_delivered">
                {t("event.filter.status.qr_code_delivered")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-qr_code_not_delivered"
                checked={checkedFilter === "qr_code_not_delivered"}
                onChange={() => onCheck("qr_code_not_delivered")}
              />
              <label htmlFor="filter-qr_code_not_delivered">
                {t("event.filter.status.qr_code_not_delivered")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-unsubscribed"
                checked={checkedFilter === "unsubscribed"}
                onChange={() => onCheck("unsubscribed")}
              />
              <label htmlFor="filter-unsubscribed">
                {t("event.filter.status.unsubscribed")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-blocked"
                checked={checkedFilter === "blocked"}
                onChange={() => onCheck("blocked")}
              />
              <label htmlFor="filter-blocked">
                {t("event.filter.status.blocked")}
              </label>
            </div>
          </div>
        </div>
        <span className="dropdown-filter__title mt-4">
          {t("thank.filter.freeReminder.title")}
        </span>
        <div className="check-list">
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-yes"
              checked={checkedReminder === "Yes"}
              onChange={() => onCheckReminder("Yes")}
            />
            <label htmlFor="filter-yes">
              {t("thank.filter.freeReminder.yes")}
            </label>
          </div>
          <div className="check-item">
            <input
              type="checkbox"
              id="filter-no"
              checked={checkedReminder === "No"}
              onChange={() => onCheckReminder("No")}
            />
            <label htmlFor="filter-no">
              {t("thank.filter.freeReminder.no")}
            </label>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default GuestsFilter
