import { Dropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { IconFilter } from "../utils/Icons/CustomIcons"

const GuestsFilter = ({
  filter,
  setFilter,
  setCheckedFilter,
  checkedFilter,
}: any) => {
  const { t } = useTranslation()

  const onCheck = (name: any) => {
    if (checkedFilter === name) {
      setCheckedFilter("")
      // setFilter({ ...filter, statusNumber: "0,1,2,5,6" })
    } else {
      setCheckedFilter(name)
      let status: any
      switch (name) {
        case "scanned":
          status = "4"
          break
        case "failed":
          status = "6"
          break
        case "going":
          status = "1"
          break
        case "decline":
          status = "2"
          break
        case "ignore":
          status = "0"
          break

        case "all":
          status = "10"
          break
        case "later":
          status = "3"
          break
        case "visited":
          status = "5"
          break
        case "not_paid":
          status = "7"
          break
        case "delivered":
          status = "8"
          break
        case "read":
          status = "9"
          break
        case "qr_code_delivered":
          status = "11"
          break
        case "qr_code_not_delivered":
          status = "12"
          break
        case "unsubscribed":
          status = "13"
          break
        case "blocked":
          status = "14"
          break
      }
      setFilter({ ...filter, statusNumber: status })
    }
  }

  return (
    <Dropdown
      className={
        "dropdown-block dropdown--not-arrow dropdown-filter guests-filter"
      }
    >
      <Dropdown.Toggle className="dropdown-block-text border-0 bg-transparent event-page__btn">
        <IconFilter />
      </Dropdown.Toggle>
      {/* "decline": "Decline",
        "later": "Later",
        "waiting": "Waiting",
        "visited": "Visited",
        "not_paid": "Not paid ",
        "delivered": "Delivered ",
        "read": "Read",
        "qr_code_delivered ": "Qr code delivered",
        "qr_code_not_delivered": "Qr code not delivered"
        
          "unsubscribed": "Unsubscribed",
        "blocked": "Blocked"*/}
      <Dropdown.Menu style={{ width: "300px" }}>
        <span className="dropdown-filter__title">
          {t("event.filter.status.title")}
        </span>
        <div
          // className="check-list"
          style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-all"
                checked={checkedFilter === "all"}
                onChange={() => onCheck("all")}
              />
              <label htmlFor="filter-all">{t("event.filter.status.all")}</label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-scanned"
                checked={checkedFilter === "scanned"}
                onChange={() => onCheck("scanned")}
              />
              <label htmlFor="filter-scanned">
                {t("event.filter.status.scanned")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-failed"
                checked={checkedFilter === "failed"}
                onChange={() => onCheck("failed")}
              />
              <label htmlFor="filter-failed">
                {t("event.filter.status.failed")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-going"
                checked={checkedFilter === "going"}
                onChange={() => onCheck("going")}
              />
              <label htmlFor="filter-going">
                {t("event.filter.status.going")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-decline"
                checked={checkedFilter === "decline"}
                onChange={() => onCheck("decline")}
              />
              <label htmlFor="filter-decline">
                {t("event.filter.status.decline")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-ignore"
                checked={checkedFilter === "ignore"}
                onChange={() => onCheck("ignore")}
              />
              <label htmlFor="filter-ignore">
                {t("event.filter.status.ignore")}
              </label>
            </div>

            <div className="check-item">
              <input
                type="checkbox"
                id="filter-later"
                checked={checkedFilter === "later"}
                onChange={() => onCheck("later")}
              />
              <label htmlFor="filter-later">
                {t("event.filter.status.later")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-visited"
                checked={checkedFilter === "visited"}
                onChange={() => onCheck("visited")}
              />
              <label htmlFor="filter-visited">
                {t("event.filter.status.visited")}
              </label>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-not_paid"
                checked={checkedFilter === "not_paid"}
                onChange={() => onCheck("not_paid")}
              />
              <label htmlFor="filter-not_paid">
                {t("event.filter.status.not_paid")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-delivered"
                checked={checkedFilter === "delivered"}
                onChange={() => onCheck("delivered")}
              />
              <label htmlFor="filter-delivered">
                {t("event.filter.status.delivered")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-read"
                checked={checkedFilter === "read"}
                onChange={() => onCheck("read")}
              />
              <label htmlFor="filter-read">
                {t("event.filter.status.read")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-qr_code_delivered"
                checked={checkedFilter === "qr_code_delivered"}
                onChange={() => onCheck("qr_code_delivered")}
              />
              <label htmlFor="filter-qr_code_delivered">
                {t("event.filter.status.qr_code_delivered")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-qr_code_not_delivered"
                checked={checkedFilter === "qr_code_not_delivered"}
                onChange={() => onCheck("qr_code_not_delivered")}
              />
              <label htmlFor="filter-qr_code_not_delivered">
                {t("event.filter.status.qr_code_not_delivered")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-unsubscribed"
                checked={checkedFilter === "unsubscribed"}
                onChange={() => onCheck("unsubscribed")}
              />
              <label htmlFor="filter-unsubscribed">
                {t("event.filter.status.unsubscribed")}
              </label>
            </div>
            <div className="check-item">
              <input
                type="checkbox"
                id="filter-blocked"
                checked={checkedFilter === "blocked"}
                onChange={() => onCheck("blocked")}
              />
              <label htmlFor="filter-blocked">
                {t("event.filter.status.blocked")}
              </label>
            </div>
          </div>
        </div>

        <span className="dropdown-filter__title">
          {t("event.filter.calledNotes.title")}
        </span>

        <div className="check-list">
          <div className="check-item">
            <input type="checkbox" id="filter-confirn" />
            <label htmlFor="filter-confirn">
              {t("event.filter.calledNotes.confirn")}
            </label>
          </div>
          <div className="check-item">
            <input type="checkbox" id="filter-notConfirm" />
            <label htmlFor="filter-notConfirm">
              {t("event.filter.calledNotes.notConfirm")}
            </label>
          </div>
          <div className="check-item">
            <input type="checkbox" id="filter-lacks" />
            <label htmlFor="filter-lacks">
              {t("event.filter.calledNotes.lacks")}
            </label>
          </div>
          <div className="check-item">
            <input type="checkbox" id="filter-incorrect" />
            <label htmlFor="filter-incorrect">
              {t("event.filter.calledNotes.incorrect")}
            </label>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default GuestsFilter
