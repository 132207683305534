import ColorPeekIcon from "@/assets/icons/ColorPeekIcon"
import { useEffect, useState } from "react"
import styles from "./QrDesignColorPeek.module.scss"
import ColorPeekIconText from "@/assets/icons/ColorPeekIconText"
import ColorPeekIconQR from "@/assets/icons/ColorPeekIconQR"
interface Option {
  label: string | React.Component
  value: string
}

const ColorPeekCircle = ({
  bgImage,
  color,
}: {
  bgImage?: string
  color?: string
}) => (
  <div
    className="rounded-circle border"
    style={{
      backgroundColor: color ?? "transparent",
      width: 30,
      height: 30,
      overflow: "hidden",
    }}
  >
    {bgImage ? <img src={bgImage} alt="" width={30} height={30} /> : ""}
  </div>
)
const paletteColour = [
  {
    value: "transparent",
    label: <ColorPeekCircle bgImage="/images/qr-design/transparentFill.png" />,
  },
  {
    value: "#FFFFFF",
    label: <ColorPeekCircle color="#FFFFFF" />,
  },
  {
    value: "#FFB500",
    label: <ColorPeekCircle color="#FFB500" />,
  },
  {
    value: "#FCFF58",
    label: <ColorPeekCircle color="#FCFF58" />,
  },
  {
    value: "#00C12B",
    label: <ColorPeekCircle color="#00C12B" />,
  },
  {
    value: "#0096DE",
    label: <ColorPeekCircle color="#0096DE" />,
  },
  {
    value: "#004DA0",
    label: <ColorPeekCircle color="#004DA0" />,
  },
  {
    value: "#8D00A4",
    label: <ColorPeekCircle color="#8D00A4" />,
  },
]

const paletteText = [
  {
    value: "black",
    label: <ColorPeekCircle color="#000000" />,
  },
  {
    value: "#FF0000",
    label: <ColorPeekCircle color="#FF0000" />,
  },
  {
    value: "#FFB500",
    label: <ColorPeekCircle color="#FFB500" />,
  },
  {
    value: "#FCFF58",
    label: <ColorPeekCircle color="#FCFF58" />,
  },
  {
    value: "#00C12B",
    label: <ColorPeekCircle color="#00C12B" />,
  },
  {
    value: "#0096DE",
    label: <ColorPeekCircle color="#0096DE" />,
  },
  {
    value: "#004DA0",
    label: <ColorPeekCircle color="#004DA0" />,
  },
  {
    value: "#8D00A4",
    label: <ColorPeekCircle color="#8D00A4" />,
  },
]

type ColorProps = {
  selectedOption: string
  setSelectedOption: any
  type: string
  showOthers?: any
  hide: any
  setHide: any
  colors: string[]
}
const CustomSelect = ({
  selectedOption,
  setSelectedOption,
  type,
  showOthers,
  hide,
  setHide,
  colors,
}: ColorProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [filteredColors, setFilteredColors] = useState<{ value: string; label:any; }[]>(
    [
      {
        value: "transparent",
        label: <ColorPeekCircle bgImage="/images/qr-design/transparentFill.png" />,
      },
    ]
  )
  const rgbToHex = (rgb: string) => {
    const match = rgb.match(/\d+/g); // Extract numbers
    if (!match) return "#000000"; // Default if parsing fails
  
    const [r, g, b] = match.map(Number);
    return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
  };
  useEffect(() => {
    console.log("colors received:", colors); // 🛠 Debugging
  
    const newColors = colors.map((rgb) => {
      const hex = rgbToHex(rgb);
      return {
        value: hex,
        label: <ColorPeekCircle color={hex} />,
      };
    });
  
    console.log("newColors:", newColors); // 🛠 Debugging
  
    setFilteredColors([
      {
        value: "transparent",
        label: <ColorPeekCircle bgImage="/images/qr-design/transparentFill.png" />,
      },
      ...newColors,
    ]);
  }, [colors]);
  const handleOptionClick = (value: string) => {
    if (value === "black") {
      setSelectedOption("black")
    } else {
      setSelectedOption(value)
    }
    setIsOpen(false)
  }

  const toggleSelect = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div
      className={
        showOthers ? styles["custom-select"] : styles["custom-select-end"]
      }
    >
      <div
        className={styles["selected-option"]}
        onClick={() =>
          setHide(
            type === "color"
              ? { font: false, align: false, color: !hide.color, text: false }
              : { font: false, align: false, color: false, text: !hide.text },
          )
        }
      >
        {type === "text" ? (
          !showOthers ? (
            <ColorPeekIconQR color={selectedOption} />
          ) : (
            <ColorPeekIconText color={selectedOption} />
          )
        ) : (
          <ColorPeekIcon color={selectedOption} />
        )}
        <img
          src="/vector/QrDesign/down-arrow.svg"
          alt=""
          style={{
            transform:
              (type === "text" && hide.text) || (type === "color" && hide.color)
                ? "rotate(180deg)"
                : "",
            marginLeft: 10,
          }}
        />
      </div>
    
        <ul className={styles["options-list"]}>
          {type === "text" && hide.text
            && filteredColors.filter((option) => option.value !== "transparent").map((option) => (
                <li
                  key={option.value}
                  onClick={() => {
                    handleOptionClick(option.value)
                    setHide({ ...hide, text: false })
                  }}
                >
                  {option.label}
                </li>
              ))}
             {type === "color" && hide.color &&
              filteredColors.map((option) => (
                <li
                  key={option.value}
                  onClick={() => {
                    handleOptionClick(option.value)
                    setHide({ ...hide, color: false })
                  }}
                >
                  {option.label}
                </li>
              ))}
        </ul>
      
    </div>
  )
}

export default CustomSelect
