import { useActions } from "@/hooks/useActions"
import { useTypeSelector } from "@/hooks/useTypeSelector"
import React, { useState, useRef } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
// import Cropper from "react-easy-crop"
import Cropper, { ReactCropperElement } from "react-cropper"
import { IoClose } from "react-icons/io5"

import "cropperjs/dist/cropper.css"

type Props = {
}

const CropModal = () => {
  const cropperRef = useRef<ReactCropperElement>(null)
  const [aspectRatio, setAspectRatio] = useState<number>(16 / 9)
  const { toggleCropModalShow, setCroppedImagePath } = useActions()
  const image = useTypeSelector((state) => state.qr.image)

  const { cropModalShow,type } = useTypeSelector((state) => state.qr)

  const onCloseModal = () => {
    toggleCropModalShow(type)
  }
  const onSave = () => {
    if (image?.path && type!=="Crop") {
      setCroppedImagePath(image.path);
      onCloseModal();
    }
    else{
    const cropper = cropperRef.current?.cropper

    console.debug("Cropper data", cropper)
    if (typeof cropper !== "undefined") {
      const croppedImageUrl = cropper.getCroppedCanvas().toDataURL()
      setCroppedImagePath(croppedImageUrl)
      onCloseModal()
    }
    }


  }

  // Handle image loading to calculate aspect ratio
  const onImageLoaded = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const img = e.currentTarget
    const calculatedAspect = img.naturalWidth / img.naturalHeight
    // setAspectRatio(calculatedAspect)
    console.info("Calculate aspect", calculatedAspect)
  }
  return (
    <Modal
      size="xl"
      onHide={onCloseModal}
      show={cropModalShow}
      centered
      backdrop="static"
      keyboard={false}
      // className="qr-crop-modal"
      className="d-flex flex-row justify-space-between"
    >
      <Modal.Header>
        <Modal.Title className="mt-2 mb-2 ms-3 me-2" style={{ width: "100%" }}>
          <div
            className="d-flex flex-row justify-content-between"
            style={{ width: "100%" }}
          >
            <div>Crop Image</div>{" "}
            <div style={{ cursor: "pointer" }}>
              <IoClose onClick={onCloseModal} />
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          // backgroundColor: "red",
          
          maxHeight: "70vh",
          overflow: "hidden",
          display: "flex",
          padding:type!=="upload"?"0px":"10px",
          justifyContent: "center",
          height:
            window.innerWidth > 1279
              ? "648px"
              : window.innerWidth > 991
              ? "415px"
              : "150px",
        }}
      >
        {image?.path && (
          <>
            <img
              src={image?.path}
              alt="Source"
              onLoad={onImageLoaded}
              style={{display:type!=="Upload"?"none":"block"}}
            />
            {type!=="Upload"?<Cropper
              ref={cropperRef}
              style={{ height: "100%", width: "100%", maxHeight: "600px" }}
              // zoomTo={0.5}
              initialAspectRatio={aspectRatio}
              preview=".img-preview"
              src={image?.path}
              viewMode={2} // Ensures the image cannot move out of canvas
              autoCropArea={1} // Controls the cropping area size
              restore={false}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              responsive={false}
              cropBoxMovable={true}
              cropBoxResizable={true}
              checkOrientation={false}
              guides={true}
            />:null}
          </>
        )}
      </Modal.Body>
      <Modal.Footer style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={onCloseModal} variant="light">
          Cancel
        </Button>
        <Button onClick={onSave} variant="dark">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CropModal
