import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Button from "react-bootstrap/Button"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../redux/store"
import EventsControls from "../../components/Events/EventsControls/EventsControls"
import Events from "../../components/Events/Events"
// import { IEvent } from "@/components/Events/types"
import {
  getEvents,
  setCurrentPage,
} from "../../redux/slices/events/eventsSlice"
import Paginator from "../../components/utils/Pagination/Pagination"
import Preloader from "../../components/utils/Preloader/Preloader"
import NoEventsPage from "./NoEventsPage"
const EventsPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { data, total_pages, current_page, isLoading, total } = useAppSelector(
    (state) => state.events,
  )

  // const [eventsLoadMore, setEventsLoadMore] = useState<IEvent[]>([])
  // const [events, setEvents] = useState<IEvent[]>([])
  const [filter, setFilter] = useState({
    sort: "desc",
    order: "orderBy",
    searchValue: "",
    status: "",
  })

  const onPageChanged = (page: number) => {
    dispatch(setCurrentPage(page))
    dispatch(getEvents(filter))
  }

  // useEffect(() => {
  //   dispatch(getEvents(filter))
  //   return () => {
  //     dispatch(clearEvents())
  //   }
  // }, [])

  // useEffect(() => {
  //   if (eventsLoadMore.length > 0) {
  //     setEvents([...eventsLoadMore, ...data])
  //     setEventsLoadMore([])
  //   } else {
  //     setEvents(data)
  //   }
  // }, [data])

  useEffect(() => {
    dispatch(setCurrentPage(1))
    dispatch(getEvents(filter))
  }, [dispatch, filter.sort, filter.searchValue, filter.status])

  return (
    <>
      {!isLoading ? (
        <>
          {data.length === 0 &&
          filter.searchValue === "" &&
          filter.status === "" ? (
            <>
              <NoEventsPage />
            </>
          ) : (
            <div className="events-section">
              <div className="container events-container">
                <EventsControls filter={filter} setFilter={setFilter} />
                {data.length !== 0 ? (
                  <>
                    <Events events={data} />
                    <Button
                      onClick={() => {
                        // setEventsLoadMore(data)
                        dispatch(
                          setCurrentPage(
                            current_page === total_pages ? 1 : current_page + 1,
                          ),
                        )
                        dispatch(getEvents(filter))
                      }}
                      className={
                        total > data.length
                          ? "btn btnloadMore d-lg-none d-sm-flex"
                          : "d-none"
                      }
                    >
                      {t("buttons.loadMore")}
                    </Button>
                    <Paginator
                      total_pages={total_pages}
                      current_page={current_page}
                      onPageChanged={onPageChanged}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <Preloader />
      )}
    </>
  )
}

export default EventsPage
