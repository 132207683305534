import { createSlice } from "@reduxjs/toolkit"

export interface IStore {
  name: null | string
  image: { path: string; size: number; name: string } | null

  croppedImagePath: null | string
  cropModalShow: boolean
  type:string
}

const initialState: IStore = {
  name: "",
  image: null,
  croppedImagePath: null,
  cropModalShow: false,
  type:"Upload"
}

export const qrSlice = createSlice({
  name: "qr-design",
  initialState,
  reducers: {
    setImage: (state, { payload }) => {
      if (payload) {
        const imagePath = URL.createObjectURL(payload)
        state.image = { path: imagePath, size: payload.size, name: payload.name }
      }
    },
    setCreatedImage: (state, { payload }) => {
      if (payload) {
        state.image = {path: payload.path, name: payload.name, size: payload.image.size }
      }
    },
    setNameQr: (state, { payload }) => {
      state.name = payload
    },
    deleteImage: (state) => {
      state.image = null
      state.croppedImagePath = null
    },
    setCroppedImagePath: (state, action) => {
      state.croppedImagePath = action.payload
    },
    toggleCropModalShow: (state,{payload}:{payload:string}) => {
      state.cropModalShow = !state.cropModalShow
      state.type=payload??"Crop"
    },
  },
})

export const { actions, reducer } = qrSlice
