import React, { useRef, useState, useEffect, useCallback } from "react"
import { Layer, Image, Transformer } from "react-konva"
import Konva from "konva"
import { URLImage } from "./QRCodeDownLoad"

interface QRLayerProps {
  qrObj: {
    x: number
    y: number
    width: number
    height: number
    textEditVisible: boolean
    color: string
    fill: string
    isEditable: boolean
  }
  width: number
  imageSrc: HTMLImageElement | null
  imageClose: HTMLImageElement
  imageEdit: HTMLImageElement
  show: boolean
  handleCloseQR: (e: any) => void
  // handleQrDragMove: (e: any) => void
  // handleQrDragStart: () => void
  canvasRef: any
  handleQrDragEnd: (e: any) => void
  handleQRDblClick: () => void
  handleQrResize: (width: number, height: number, x: number, y: number) => void
  qrRef: React.RefObject<Konva.Image>
}

const QRLayer: React.FC<QRLayerProps> = ({
  qrObj,
  width,
  imageSrc,
  imageClose,
  imageEdit,
  show,
  handleCloseQR,
  handleQRDblClick,
  handleQrResize,
  canvasRef,
  // handleQrDragMove,
  // handleQrDragStart,
  handleQrDragEnd,
  qrRef,
}) => {
  const [isSelected, setIsSelected] = useState(false)
  const imageRef = useRef<Konva.Image>(null)
  const transformerRef = useRef<Konva.Transformer>(null)

  useEffect(() => {
    if (isSelected && transformerRef.current && qrRef.current) {
      transformerRef.current.nodes([qrRef.current])
      transformerRef.current.getLayer()?.batchDraw()
    }
  }, [isSelected, qrRef])

  const handleSelect = useCallback(() => {
    setIsSelected(true)
    handleQRDblClick()
  }, [handleQRDblClick])

  const handleDeselect = useCallback(
    (e: any) => {
      setIsSelected(false)
      handleCloseQR(e)
    },
    [handleCloseQR],
  )
  const getDragBoundFunc = (nodeRef: React.RefObject<any>) => {
    return (pos: { x: number; y: number }) => {
      if (!nodeRef.current || !canvasRef.current) return pos;
      
      const node = nodeRef.current;
      const bgRect = canvasRef.current.getBoundingClientRect();
      
      // Get actual dimensions considering scale and rotation
      const width = node.width() * node.scaleX();
      const height = node.height() * node.scaleY();
      
      // Account for rotation (simplified - assumes rectangular bounds)
      const rotation = node.rotation() || 0;
      const rad = (rotation * Math.PI) / 180;
      const effectiveWidth = Math.abs(width * Math.cos(rad)) + Math.abs(height * Math.sin(rad));
      const effectiveHeight = Math.abs(height * Math.cos(rad)) + Math.abs(width * Math.sin(rad));
      
      // Calculate safe boundaries with padding if needed
      const padding = 2; // Optional padding from edges
      const minX = padding;
      const maxX = Math.max(minX, bgRect.width - effectiveWidth - padding);
      const minY = padding;
      const maxY = Math.max(minY, bgRect.height - effectiveHeight - padding);
      
      // Return bounded position
      return {
        x: Math.max(minX, Math.min(pos.x, maxX)),
        y: Math.max(minY, Math.min(pos.y, maxY))
      };
    };
  };
  const handleTransform = useCallback(() => {
    const node = qrRef.current
    if (!node) return

    // Get the current transform state
    const scaleX = node.scaleX()
    const scaleY = node.scaleY()

    // Calculate new dimensions (maintain aspect ratio)
    const newWidth = Math.round(node.width() * scaleX)
    const newHeight = newWidth // Keep square aspect ratio
    
    // Get current position
    const newX = Math.round(node.x())
    const newY = Math.round(node.y())

    // Reset scale to 1 (important!)
    node.scaleX(1)
    node.scaleY(1)

    // Apply new dimensions
    node.width(newWidth)
    node.height(newHeight)

    // Update parent component with new dimensions and position
    handleQrResize(newWidth, newHeight, newX, newY)
  }, [handleQrResize, qrRef])

  return (
    <Layer
      opacity={qrObj.textEditVisible && show === false ? 0 : 1}
      // onDragStart={handleQrDragStart}
      // onDragMove={handleQrDragMove}
    >
      <URLImage
        opacity={qrObj.textEditVisible ? 1 : 0}
        src={imageClose}
        x={qrObj.x - 11}
        y={qrObj.y - 22}
        width={22}
        height={22}
        funcClick={handleDeselect}
      />

      {imageSrc && (
        <Image
          draggable={qrObj.textEditVisible}
          ref={qrRef}
          image={imageSrc}
          onDragEnd={handleQrDragEnd}
          dragBoundFunc={getDragBoundFunc(qrRef)}
          x={qrObj.x}
          y={qrObj.y}
          width={qrObj.width}
          height={qrObj.height}
          onDblClick={handleSelect}
          onDblTap={handleSelect}
          onTransform={handleTransform}
          onTransformEnd={handleTransform}
        />
      )}

      {/* Add edit button for resizing */}
      <URLImage
        opacity={qrObj.textEditVisible ? 1 : 0}
        src={imageEdit}
        x={qrObj.x + qrObj.width - 21}
        y={qrObj.y + qrObj.height + 5}
        width={22}
        height={22}
        funcClick={() => {
          if (transformerRef.current && qrRef.current) {
            transformerRef.current.nodes([qrRef.current]);
            transformerRef.current.getLayer()?.batchDraw();
          }
        }}
      />

      {isSelected && (
        <Transformer
          ref={transformerRef}
          rotateEnabled={false}
          borderStroke="#6D6D6E"
          anchorStroke="#6D6D6E"
          borderStrokeWidth={2}
          enabledAnchors={["bottom-right"]}
          boundBoxFunc={(oldBox, newBox) => {
            // Maintain square aspect ratio
            const minSize = 50;
            const maxSize = 300;
            
            // Use width as the primary dimension
            const newSize = Math.max(minSize, Math.min(maxSize, newBox.width));
            
            return {
              ...newBox,
              width: newSize,
              height: newSize, // Keep square aspect ratio
            }
          }}
        />
      )}
    </Layer>
  )
}

export { QRLayer }
