import enpoints from "../endpoints"
import { axiosInstance } from "../instance"
import axios, { AxiosPromise } from "axios"
import { IEventsRequest, IEventsResponse } from "../../components/Events/types"

export const events = (
  currentPage: number,
  params: { sort: string; order: string; searchValue: string; status: string },
): AxiosPromise<IEventsResponse> => {
  let sorted = ""
  let ordered = ""
  let search = ""
  let status = ""

  switch (params.sort) {
    case "asc":
      sorted = "&sortedBy=asc"
      break
    case "desc":
      sorted = "&sortedBy=desc"
      break
    default:
      sorted = ""
      break
  }

  switch (params.order) {
    case "orderBy":
      ordered = "&orderBy=event_created_date"
      break
    default:
      ordered = ""
      break
  }

  if (params.status !== "") {
    status = `status:${params.status}`
  }

  if (params.searchValue !== "") {
    search = `;name:${params.searchValue}&searchJoin=and`
  }

  return axiosInstance.get(
    enpoints.EVENTS.GET +
      `?page=${currentPage}${sorted}${ordered}&search=${status}${search}`,
  )
}

export const eventsWithoutFilter = () => {
  return axiosInstance.get(
    enpoints.EVENTS.GET +
      `?page=1&sortedBy=asc&orderBy=event_created_date&search=`,
  )
}

export const getEvent = (id: string | number) => {
  return axiosInstance.get(enpoints.EVENTS.GET_EVENT + `/${id}`)
}

export const deleteEvent = (id: string | number) => {
  return axiosInstance.delete(enpoints.EVENTS.DELETE + `/${id}`)
}

export const createEvent = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS.UPDATE, params)
}
export const createEventTest = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS.UPDATE, params)
}

export const getEventQuesions = (id: any) => {
  return axiosInstance.get(enpoints.EVENTS.GET_QUESTIONS + `?event_id=${id}`)
}

export const createEventQuesions = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS.CREATE_QUESTIONS, params)
}

export const updateEventQuesions = (id: number, params: any) => {
  return axiosInstance.put(enpoints.EVENTS.UPDATE_QUESTIONS + `/${id}`, params)
}

export const deleteEventQuesions = (id: number) => {
  return axiosInstance.delete(enpoints.EVENTS.DELETE_QUESTIONS + `/${id}`)
}

export const createQuesionsOption = (id: number, params: any) => {
  return axiosInstance.post(
    enpoints.EVENTS.CREATE_OPTIONS + `/${id}/options`,
    params,
  )
}

export const updateQuesionsOption = (id: number, params: any) => {
  return axiosInstance.put(enpoints.EVENTS.UPDATE_OPTIONS + `/${id}`, params)
}

export const deleteQuesionsOption = (id: number) => {
  return axiosInstance.delete(enpoints.EVENTS.DELETE_OPTIONS + `/${id}`)
}

export const sendOpenSettings = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS.SEND_SETTINGS, params)
}

export const getEventByHash = (hash: string) => {
  return axiosInstance.get(enpoints.EVENTS.GET_EVENT_BY_HASH + `/${hash}`)
}

export const loadImgEvent = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS.LOAD_IMG, params)
}

export const deleteImgEvent = (id: string | number) => {
  return axiosInstance.delete(enpoints.EVENTS.LOAD_IMG + `/${id}`)
}

export const getPreview = () => {
  return axiosInstance.get(enpoints.EVENTS.PREVIEW)
}

export const eventDesign = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS.DESIGN, params)
}

export const sendQrDesign = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS.QR_DESIGN, params)
}
export const uploadImageToQrCode = (params: any) => {
  const formData = new FormData();
  formData.append("image", params.image); // Append the image file

  return axiosInstance.post(
    `${enpoints.EVENTS.QR_DESIGN}/${params.qrCodeDesignId}/upload-image`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};


export const getQrDesign = (id: any) => {
  return axiosInstance.get(enpoints.EVENTS.QR_DESIGN + `/${id}`)
}

export const getQrImage = (params: any) => {
  return axiosInstance.get(
    enpoints.EVENTS.GET_QR + `/${params.id}?hash=${params.hash}`,
  )
}

export const updateQrDesign = (params: any) => {
  return axiosInstance.put(
    enpoints.EVENTS.UPDATE_QR_DESIGN + `/${params.id}`,
    params.params,
  )
}

export const uploadQRImage = (params: any) => {
  return axiosInstance.post(
    enpoints.EVENTS.UPLOAD_QR_IMAGE + `${params.id}/upload-image`,
    params.params,
  )
}

export const sendInvitationAll = (id: string | number) => {
  return axiosInstance.post(
    enpoints.EVENTS.SEND_INVITATION_ALL + `?event_id=${id}`,
  )
}

export const sendInvitation = (
  phone: string | number,
  text: string | number,
) => {
  return enpoints.EVENTS.SEND_INVITATION + `?phone=${phone}&text=${text}`
}

export const testTheInvitation = (phone: string | number) => {
  return axiosInstance.post(
    enpoints.EVENTS.TEST_THE_INVITATION + `?phone=${phone}`,
  )
}

export const validateOTP = (otp: string | number) => {
  return axiosInstance.post(enpoints.EVENTS.VALIDATE_OTP + `?otp=${otp}`)
}

export const updateInvites = (params: any) => {
  return axiosInstance.post(
    enpoints.EVENTS.UPDATE_INVITES + `${params.id}/mass-update-quantity`,
    { quantity: params.quantity },
  )
}

export const updateInvite = (params: {
  event_id: number
  contact_id: number
  quantity_members: number
}) => {
  return axiosInstance.post(enpoints.EVENTS.UPDATE_INVITE, {
    event_id: params.event_id,
    language: "en",
    contacts: [
      {
        contact_id: params.contact_id,
        quantity_members: params.quantity_members,
      },
    ],
  })
}

export const sendQR = (id: string | number) => {
  return axiosInstance.post(enpoints.EVENTS.SEND_QR, { event_id: id })
}

export const sendWAInvite = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS.SEND_WA_INVITE, {
    event_id: params.eventId,
    event_contact_ids: params.contactIds,
  })
}

export const getBalance = () => {
  return axiosInstance.get(enpoints.EVENTS.GET_BALANCE)
}

export const sendReminder = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS.SEND_REMINDER, params)
}

export const sendThankYouMessage = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS.SEND_THANK_YOU_MESSAGE, params)
}

export const sendTestInvitation = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS.SEND_TEST_INVITATION, params)
}
export const sendTestInvitationTwo = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS.SEND_TEST_INVITATION_TWO, params)
}

export const sendTestInvitationOne = async (params: any) => {
  try {
    const response = await axiosInstance.post(
      enpoints.EVENTS.SEND_TEST_INVITATION,
      params,
      { withCredentials: true },
    )
    console.log("response-response", response)
    return response // Make sure this returns the response correctly
  } catch (error) {
    console.error("Error in sendTestInvitation:", error)
    throw error // Rethrow error so that .catch in calling function works
  }
}

export const getTestInvitation = (id: any) => {
  return axiosInstance.get(
    enpoints.EVENTS.GET_TEST_INVITATION + "?event_id=" + `${id}`,
  )
}

export const getTestWhasappInvitation = (id: any) => {
  return axiosInstance.get(
    enpoints.EVENTS.GET_TEST_WHATSAPP_INVITATION + "?event_id=" + `${id}`,
  )
}

export const getThankMessage = (id: any) => {
  return axiosInstance.get(
    enpoints.EVENTS.GET_THANK_MESSAGE + "?event_id=" + `${id}`,
  )
}

export const regiterAnswer = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS.REGISTER_ANSWER, params)
}

export const copyEvent = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS.COPY_EVENT, params)
}

export const checkDiscount = (code: string) => {
  return axiosInstance.get(
    enpoints.EVENTS.CHECK_DISCOUNT + "?code=" + `${code}`,
  )
}

export const getPricing = () => {
  return axiosInstance.get(enpoints.EVENTS.GET_PRICING)
}

export const checkCharge = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS.CHECK_CHARGE, params)
}

export const getEventImages = (id: string | number) => {
  return axiosInstance.get(enpoints.EVENTS.GET_ALL_IMAGES + `/${id}/photo`)
}
export const enablePov = (id: string | number, povEnabled: boolean) => {
  return axiosInstance.patch(enpoints.EVENTS.ENABLE_POV + `/${id}`, {
    povEnabled: povEnabled,
  })
}

export const getEventByPhone = (params: string) => {
  console.log("params", params)
  return axiosInstance.get(
    enpoints.EVENTS?.EVENT_BY_PHONE_NUMBER + `/${params}`,
  )
}
export const sendReponseForInvite = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS.SEND_RESPONSE_FOR_INVITE, {
    hash: params?.hash,
    status: params?.status,
    amount_of_guests: params?.numberOfGuests,
  })
}

export const getQuestions = () => {
  return axiosInstance.get(enpoints.EVENTS?.QUESTIONS)
}
export const quizAnswers = (params: any) => {
  return axiosInstance.post(enpoints.EVENTS?.QUIZ_ANSWER, {
    user_id: params.userId,
    choice_id: params.answersId,
  })
}
