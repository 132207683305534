import { useEffect, useState } from "react"
import { Toaster } from "react-hot-toast"
import { useAppDispatch, useAppSelector } from "@/redux/store"
import QrDesignUploadedFile from "@/components/QrDesign/UI/QrDesignUploadedFile"
import CropModal from "@/components/QrDesign/cropModal/CropModal"
import QrDesignFileLoader from "@/components/QrDesign/file-load/QrDesignFileLoader"
import { useTypeSelector } from "@/hooks/useTypeSelector"
import { PiCrownFill } from "react-icons/pi"
import {
  getEvent,
  getQrDesign,
  getQrImage,
  setLoading,
} from "@/redux/slices/events/eventsSlice"
import { useParams } from "react-router-dom"
import { useActions } from "@/hooks/useActions"
import Preloader from "@/components/utils/Preloader/Preloader"
import DeleteImageModal from "@/components/QrDesign/DeleteImageModal/DeleteImageModal"
import QrDesignEditor from "./QrDesignEditor/QrDesignEditor"
import styles from "./QrDesignPage.module.scss"

const QrDesignPage = () => {
  const { image, croppedImagePath } = useTypeSelector((state) => state.qr)
  const [colors, setColors] = useState<string[]>([])
  const { currentEvent, isLoading } = useAppSelector((state) => state.events)
  const { deleteImage } = useActions()
  const [showImageModal, setShowImageModal] = useState(false)
  const [qrDesignName, setQrDesignName] = useState("")
  const [savePreview, setSavePreview] = useState(false)
  const [edit, setEdit] = useState(false)
  const [file, setFile] = useState<Blob | MediaSource | null>(null)
  const dispatch = useAppDispatch()
  const { event_id } = useParams()

  useEffect(() => {
    event_id && dispatch(getEvent(Number(event_id)))
  }, [event_id])

  useEffect(() => {
    return () => {
      deleteImage()
    }
  }, [])
  const extractColorsFromImage = async (imageUrl: string) => {
    return new Promise<string[]>((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // Prevent CORS issues
      img.src = imageUrl;
  
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        if (!ctx) {
          reject("Canvas not supported");
          return;
        }
  
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
  
        const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
  
        let colorCounts: { [key: string]: number } = {};
  
        for (let i = 0; i < imageData.length; i += 4) {
          let r = imageData[i];
          let g = imageData[i + 1];
          let b = imageData[i + 2];
  
          // 🔹 Round RGB values to group similar shades
          r = Math.round(r / 20) * 20;
          g = Math.round(g / 20) * 20;
          b = Math.round(b / 20) * 20;
  
          const color = `rgb(${r},${g},${b})`;
  
          colorCounts[color] = (colorCounts[color] || 0) + 1;
        }
  
        // 🔥 Merge similar colors using a color distance function
        const filteredColors: string[] = [];
        Object.keys(colorCounts).forEach((color) => {
          if (!filteredColors.some((c) => areColorsSimilar(c, color))) {
            filteredColors.push(color);
          }
        });
  
        // First, slice the top 6 colors
        let finalColors = filteredColors.slice(0, 6);
  
        // Ensure black and white are included
        if (!finalColors.includes("rgb(255,255,255)")) {
          finalColors.push("rgb(255,255,255)");
        }
        if (!finalColors.includes("rgb(0,0,0)")) {
          finalColors.push("rgb(0,0,0)");
        }
  
        resolve(finalColors);
      };
  
      img.onerror = reject;
    });
  };
  
  // 🎨 Function to check if two colors are similar (Euclidean distance)
  const areColorsSimilar = (color1: string, color2: string, threshold = 50) => {
    const rgb1 = color1.match(/\d+/g)?.map(Number);
    const rgb2 = color2.match(/\d+/g)?.map(Number);
  
    if (!rgb1 || !rgb2) return false;
  
    const distance = Math.sqrt(
      Math.pow(rgb1[0] - rgb2[0], 2) +
      Math.pow(rgb1[1] - rgb2[1], 2) +
      Math.pow(rgb1[2] - rgb2[2], 2)
    );
  
    return distance < threshold; // If the colors are close, they are considered duplicates
  };
  
  
  
  

  useEffect(() => {
    if (croppedImagePath) {
      extractColorsFromImage(croppedImagePath).then((colors) => {
        setColors(colors)
      })
    }
  }, [croppedImagePath])
  useEffect(() => {
    
    event_id &&
      currentEvent &&
      currentEvent.qr_code_design_id !== null &&
      setEdit(true)
    if (
      currentEvent?.qr_code_design_id !== null &&
      currentEvent?.qr_code_design_id !== undefined
    ) {
      dispatch(setLoading())
      dispatch(getQrDesign(currentEvent?.qr_code_design_id)).then(
        (res: any) => {
          const urlParams = new URLSearchParams(
            res.payload.data.image_url.split("?")[1],
          )
          dispatch(
            getQrImage({
              id: res.payload.data.id,
              hash: urlParams.get("hash"),
            }),
          )
          setQrDesignName(res.payload.data.name)
        },
      )
    }
    // if (currentEvent && currentEvent.qr_code_design_id !== null) {
    //   setSavePreview(true)
    // }
  }, [currentEvent])
  return (
    <div className="qrCreatePage">
      {isLoading ? (
        <Preloader />
      ) : (
        <div className="container">
          <div
            className="d-flex align-items-center gap-2"
            style={{ marginBottom: "24px" }}
          >
            <PiCrownFill color="#F9B81A" size={32} />
            <h2 className="caption-two">
              {edit ? "Edit QR Code Design" : "Create QR Code Design"}
            </h2>
          </div>
          <div className={styles.main_block} style={{ height: "auto" }}>
            <h4
              className="caption-three"
              style={{ marginBottom: window.innerWidth > 767 ? "24px" : "0" }}
            >
              QR Code Design
            </h4>
            <QrDesignFileLoader
              setQrDesignName={setQrDesignName}
              qrDesignName={qrDesignName}
              croppedImagePath={croppedImagePath}
              setFile={setFile}
              savePreview={savePreview}
            />
            {croppedImagePath && image && (
              <>
                {!savePreview && (
                  <div className={styles.uploaded_file}>
                    <h4 className="caption-three">Image Editor</h4>
                    <QrDesignUploadedFile
                      setShowImageModal={setShowImageModal}
                    />
                  </div>
                )}
                <div className="d-flex align-items-center justify-content-center flex-column w-100">
                  <QrDesignEditor
                    setSavePreview={setSavePreview}
                    savePreview={savePreview}
                    qrDesignName={qrDesignName}
                    setQrDesignName={setQrDesignName}
                    file={file}
                    setEdit={setEdit}
                    edit={edit}
                    colors={colors}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <DeleteImageModal show={showImageModal} setShow={setShowImageModal} />
      <CropModal/>
      <Toaster />
    </div>
  )
}

export default QrDesignPage
