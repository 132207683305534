import { useState } from "react"
import QrDesignEditorButton from "@/components/QrDesign/UI/editor-button/QrDesignEditorButton"
import QrDesignTextEditor from "./QrDesignTextEditor"
import QrCodeCanvasInput from "@/components/QrDesign/canvas/QrCodeCanvasInput"
import DeleteModal from "@/components/QrDesign/DeleteModal/DeleteModal"
import ResetModal from "@/components/QrDesign/ResetModal/ResetModal"

const QrDesignEditor = ({
  savePreview,
  setSavePreview,
  qrDesignName,
  file,
  edit,
  setEdit,
  setQrDesignName,
  colors,
}: any) => {
  const [textVisible, setTextVisible] = useState<boolean>(false)
  const [qrVisible, setQrVisible] = useState<boolean>(false)
  const [countVisible, setCountVisible] = useState<boolean>(false)
  const [selectVisible, setSelectVisible] = useState<boolean>(false)
  const [fontValue, setFontValue] = useState({
    label: "Montserrat",
    value: "Montserrat",
  })
  const [alignValue, setAlignValue] = useState({
    value: "left",
    label: <img src="/vector/QrDesign/alignt_format_left.svg" />,
  })
  const [selectedColor, setSelectedColor] = useState<string>("white")
  const [selectedTextColor, setSelectedTextColor] = useState<string>("black")
  const [showResetModal, setShowResetModal] = useState(false)
  const [bold, setBold] = useState(false)
  const [italic, setItalic] = useState(false)
  const [underlined, setUnderlined] = useState(false)
  const [showOthers, setShowOthers] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [deleteAll, setDeleteAll] = useState(false)
  const [updateText, setUpdateText] = useState(false)
  const [show, setShow] = useState(true)
  const onReset = () => {
    setAlignValue({
      value: "left",
      label: <img src="/vector/QrDesign/alignt_format_left.svg" />,
    })
    setFontValue({
      label: "Montserrat",
      value: "Montserrat",
    })
    setSelectedColor("white")
    setSelectedTextColor("black")
    setBold(false)
    setItalic(false)
    setUnderlined(false)
    setTextVisible(false)
    setQrVisible(false)
    setCountVisible(false)
    setSelectVisible(false)
    setDeleteAll(true)
    setShow(true)
    setShowResetModal(false)
  }

  const resetFilters = () => {
    setSelectedColor("white")
    setSelectedTextColor("black")
    setFontValue({
      label: "Montserrat",
      value: "Montserrat",
    })
    setAlignValue({
      value: "left",
      label: <img src="/vector/QrDesign/alignt_format_left.svg" />,
    })
    setBold(false)
    setItalic(false)
    setUnderlined(false)
  }
  return (
    <div className="d-flex align-items-center justify-content-center flex-column w-100">
      <QrDesignEditorHeading
        setTextVisible={setTextVisible}
        setQrVisible={setQrVisible}
        setCountVisible={setCountVisible}
        setSelectVisible={setSelectVisible}
        setUpdateText={setUpdateText}
        textVisible={textVisible}
        selectVisible={selectVisible}
        qrVisible={qrVisible}
        countVisible={countVisible}
        savePreview={savePreview}
        setShow={setShow}
        updateText={updateText}
        setShowResetModal={setShowResetModal}
      />
      <QrDesignTextEditor
        fontValue={fontValue}
        alignValue={alignValue}
        color={selectedColor}
        setColor={setSelectedColor}
        colorText={selectedTextColor}
        setColorText={setSelectedTextColor}
        bold={bold}
        italic={italic}
        underlined={underlined}
        showOthers={showOthers}
        savePreview={savePreview}
        setFontValue={setFontValue}
        setAlignValue={setAlignValue}
        setItalic={setItalic}
        setBold={setBold}
        setUnderlined={setUnderlined}
        setShow={setShow}
        show={show}
        colors={colors}
      />
      <QrCodeCanvasInput
        setUpdateText={setUpdateText}
        fontValue={fontValue.value}
        alignValue={alignValue.value}
        color={selectedColor}
        colorText={selectedTextColor}
        setColor={setSelectedColor}
        setColorText={setSelectedTextColor}
        setFontValue={setFontValue}
        setAlignValue={setAlignValue}
        bold={bold}
        setTextVisible={setTextVisible}
        setQrVisible={setQrVisible}
        setCountVisible={setCountVisible}
        setSelectVisible={setSelectVisible}
        updateText={updateText}
        italic={italic}
        underlined={underlined}
        textVisible={textVisible}
        qrVisible={qrVisible}
        countVisible={countVisible}
        selectVisible={selectVisible}
        resetFilters={resetFilters}
        setShowOthers={setShowOthers}
        setShowModal={setShowModal}
        setSavePreview={setSavePreview}
        savePreview={savePreview}
        qrDesignName={qrDesignName}
        file={file}
        setEdit={setEdit}
        setQrDesignName={setQrDesignName}
        deleteAll={deleteAll}
        setDeleteAll={setDeleteAll}
        setShow={setShow}
        show={show}
        edit={edit}
      />
      <DeleteModal show={showModal} setShow={setShowModal} />
      <ResetModal
        show={showResetModal}
        setShow={setShowResetModal}
        onReset={onReset}
      />
    </div>
  )
}

export default QrDesignEditor

const QrDesignEditorHeading = ({
  setTextVisible,
  setQrVisible,
  setCountVisible,
  setSelectVisible,
  savePreview,
  textVisible,
  selectVisible,
  qrVisible,
  countVisible,
  setUpdateText,
  setShow,
  updateText,
  setShowResetModal,
}: any) => {
  return (
    <>
      {!savePreview && (
        <div className="d-flex gap-3 mb-2 qr-design__header-container">
          <QrDesignEditorButton
            icon="/vector/QrDesign/crop.svg"
            text="Crop"
            setShow={setShow}
          />
          <QrDesignEditorButton
            icon="/vector/QrDesign/new-user.svg"
            text="Amount"
            setCountVisible={setCountVisible}
            countVisible={countVisible}
            setShow={setShow}
          />
          <QrDesignEditorButton
            icon="/vector/QrDesign/user.svg"
            text="Name"
            setSelectVisible={setSelectVisible}
            selectVisible={selectVisible}
            setShow={setShow}
          />
          <QrDesignEditorButton
            icon="/vector/QrDesign/text-area.svg"
            text="Text"
            setTextVisible={setTextVisible}
            setUpdateText={setUpdateText}
            textVisible={textVisible}
            setShow={setShow}
            updateText={updateText}
          />
          <QrDesignEditorButton
            icon="/vector/QrDesign/qr-code.svg"
            text="QR"
            setQrVisible={setQrVisible}
            qrVisible={qrVisible}
            setShow={setShow}
          />
          <QrDesignEditorButton
            icon="/vector/QrDesign/trash.svg"
            text="Reset"
            onReset={setShowResetModal}
            setShow={setShow}
          />
        </div>
      )}
    </>
  )
}
