import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  IconArrowPreview,
  IconArrowRight,
  IconCrown,
} from "../../components/utils/Icons/CustomIcons"
import { Tab, Tabs } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "@/redux/store"
import {
  deleteGuest,
  getContactsOnEvent,
  setCurrentPage,
  setLimit,
  setLoading,
} from "@/redux/slices/contacts/contactsSlices"
import { getEvent } from "@/redux/slices/events/eventsSlice"
const CreateEventPreview = ({
  event,
  new_event_img,
  loadPreview,
  language,
  onSubmit,
}: // allEventsData: any,
any) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { event_id } = useParams()

  const { isLoading, data_uploaded, current_page } = useAppSelector(
    (state) => state.contacts,
  )
  const [preview, setPreview] = useState(loadPreview)
  const { currentEvent } = useAppSelector((state) => state.events)
  const [filter, setFilter] = useState({
    sort: "",
    order: "",
    searchValue: "",
    statusNumber: "",
    reminderNumber: "",
    id: Number(event_id),
  })
  useEffect(() => {
    if (event) {
      dispatch(getContactsOnEvent(filter))
    }
  }, [event])

  // tabs
  const [key, setKey] = useState("tab1")
  const [opened, setOpened] = useState(false)
  console.log("language", language)
  const handleLanguage = () => {
    if (language == "English" || language == "en") {
      setPreview(loadPreview.en)
    }

    if (language == "عرب" || language == "ar") {
      setPreview(loadPreview.ar)
    }
  }

  useEffect(() => {
    handleLanguage()
  }, [])

  useEffect(() => {
    handleLanguage()
  }, [loadPreview, language])

  const languagesWelcome = {
    ar: "{{1}}\n\n نتشرف بدعوتكم لحضور {{2}}",
    en: "{{1}}\n\n We are pleased to invite you to {{2}}",
  }
  const acceptButton = {
    ar: "اعتذار",
    en: "Accept",
  }
  const declineButton = {
    ar: "تأكيد",
    en: "Decline",
  }
  const locationButton = {
    ar: "موقع المناسبة",
    en: "Event Location",
  }
  const transformInvitationBody = (
    guestName: any,
    eventName: any,
    language: any,
    newEvent: any,
  ) => {
    language
    let body = ""
    if (language === "en" || language === "English") {
      if (newEvent?.invitation_template_en_body_text) {
        body = newEvent?.invitation_template_en_body_text
      } else {
        body = languagesWelcome?.en
      }
    } else {
      if (newEvent?.invitation_template_ar_body_text) {
        body = newEvent?.invitation_template_ar_body_text
      } else {
        body = languagesWelcome?.ar
      }
    }

    // body = body.replace("{{1}}", guestName).replace("{{2}}", `${eventName}`)
    console.log("guestName-000000000", guestName)
    console.log("guestName-guestName testing---", guestName !== "")

    //   const name=data_uploaded[0]?.suffix || ""
    // } ${data_uploaded[0]?.first_name || ""} ${
    //   data_uploaded[0]?.last_name || ""
   console.log("language",language)
    body = body
      .replace(
        "{{1}}",
        guestName?.first_name &&
          guestName?.first_name !== null &&
          guestName?.first_name != undefined
          ? `${guestName?.suffix}  ${guestName?.first_name}  ${guestName?.last_name}`
          : language === "English" || language === "en"
          ? "Mr John Doe"
          : "فلان الفلاني",
      )
      .replace("{{2}}", eventName || "")

    let rows = body.split("\n")
    return rows.map((row) => <p> {row} </p>)
  }

  const getAcceptButtonText = (language: any, newEvent: any) => {
    if (language === "en" || language === "English") {
      if (newEvent?.invitation_template_en_accept_button_text) {
        return newEvent?.invitation_template_en_accept_button_text
      } else {
        // return languages[language].content.eventcreated.acceptInvitation
        return acceptButton?.en
      }
    } else {
      if (newEvent?.invitation_template_ar_accept_button_text) {
        return newEvent?.invitation_template_ar_accept_button_text
      } else {
        // return languages[language].content.eventcreated.acceptInvitation
        return acceptButton?.ar
      }
    }
  }

  const getDeclineButtonText = (language: any, newEvent: any) => {
    if (language === "en" || language === "English") {
      if (newEvent?.invitation_template_en_decline_button_text) {
        return newEvent?.invitation_template_en_decline_button_text
      } else {
        return declineButton?.en
      }
    } else {
      if (newEvent?.invitation_template_ar_decline_button_text) {
        return newEvent?.invitation_template_ar_decline_button_text
      } else {
        return declineButton?.ar
      }
    }
  }

  const getLocationButtonText = (language: any, newEvent: any) => {
    if (language === "en" || language === "English") {
      if (newEvent?.invitation_template_en_location_button_text) {
        return newEvent?.invitation_template_en_location_button_text
      } else {
        // return languages[language].content.eventcreated.eventLocation
        return locationButton?.en
      }
    } else {
      if (newEvent?.invitation_template_ar_location_button_text) {
        return newEvent?.invitation_template_ar_location_button_text
      } else {
        // return languages[language].content.eventcreated.eventLocation
        return locationButton?.en
      }
    }
  }
  const newData: any = data_uploaded
  let guestName =
    newData[0]?.suffix +
    " " +
    newData[0]?.first_name +
    " " +
    newData[0]?.last_name
  const invitationBody = transformInvitationBody(
    guestName,
    event?.name,
    language,
    currentEvent,
  )

  return (
    <div className="createEventForm__right">
      <div className="preview">
        <h3
          className={`caption caption-three ${opened && "opened"}`}
          onClick={() => {
            setOpened(!opened)
          }}
        >
          {t("eventEdit.preview")}

          <IconArrowPreview />
        </h3>
        <div className={`preview-controls ${opened && "opened"}`}>
          {event.invitation_show_qr_code && (
            <span
              onClick={(e) => {
                onSubmit(e, "qr")
              }}
              className="editTextQR"
            >
              {/*<IconCrown /> Edit QR code design */}
            </span>
          )}
          <Tabs activeKey={key} onSelect={(k: any) => setKey(k)}>
            <Tab
              className={`preview-controls__line ${
                event.invitation_show_qr_code ? "qr" : ""
              }`}
              eventKey="tab1"
              title={
                <div className="preview-name">
                  <span> {t("eventEdit.invitation")}</span>
                  <IconArrowRight />
                </div>
              }
            >
              <div className="preview-phone">
                <div className="preview-content">
                  <div className="preview-content__wrap">
                    <div className="preview-content__inner">
                      <picture className="picture">
                        <img
                          src={
                            new_event_img.file_path
                              ? new_event_img.file_path
                              : currentEvent
                              ? currentEvent.pattern
                              : "/vector/no-foto.svg"
                          }
                          alt=""
                        />
                      </picture>
                      <div className="preview-content__inner-text">
                        {/* <p>
                          {(language === "English" || language === "en") &&
                            "Mr John Doe"}

                          {(language === "عرب" || language === "ar") &&
                            "فلان الفلاني"}
                        </p>
                        <p>
                          {(language === "English" || language === "en") &&
                            "We are pleased to invite you to "}

                          {(language === "عرب" || language === "ar") &&
                            "نحن سعداء بدعوتك إلى"}
                          {event.name}
                        </p> */}
                        {transformInvitationBody(
                          data_uploaded[0],
                          event?.name,
                          language,
                          currentEvent,
                        )}

                        {/* {invitationBody} */}
                      </div>
                      <a href="#">www.mazoom.sa</a>
                      <span className="date">11.14 AM</span>
                    </div>
                    <div className="preview-invitation__controls">
                      <button className="btn">
                        {/* {preview?.invitation?.accept_button_text} */}
                        {getAcceptButtonText(language, currentEvent)}
                      </button>
                      <button className="btn">
                        {/* {preview?.invitation?.decline_button_text} */}
                        {getDeclineButtonText(language, currentEvent)}
                      </button>

                      <button className="btn">
                        {/* {preview?.invitation?.location_button_text} */}
                        {getLocationButtonText(language, currentEvent)}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              className={`preview-controls__line ${
                event.invitation_show_qr_code ? "qr" : ""
              }`}
              eventKey="tab2"
              title={
                <div className="preview-name">
                  <span> {t("eventEdit.confirm")}</span>
                  <IconArrowRight />
                </div>
              }
            >
              <div className="preview-phone">
                <div className="preview-content">
                  <div className="preview-content__wrap">
                    <div className="preview-content__inner">
                      <picture className="picture picture-confirm">
                        <img src="/images/oq-code.png" alt="" />
                      </picture>
                      <div className="preview-content__inner-text">
                        <p>
                          {
                            preview?.confirm_with_qr
                              ?.with_add_qr_to_wallet_body_text
                          }
                        </p>
                      </div>
                      <a href="#">www.mazoom.sa</a>
                      <span className="date">11.14 AM</span>
                    </div>
                    {event.qr_wallet && (
                      <div className="preview-invitation__controls">
                        <button className="btn btn--full">
                          Add to my wallet
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              className={`preview-controls__line ${
                event.invitation_show_qr_code ? "qr" : ""
              }`}
              eventKey="tab3"
              title={
                <div className="preview-name">
                  <span> {t("eventEdit.location")}</span>
                  <IconArrowRight />
                </div>
              }
            >
              <div className="preview-phone">
                <div className="preview-content">
                  <div className="preview-content__wrap">
                    <div className="preview-content__inner">
                      {event.address.address && (
                        <picture className="picture">
                          <img src="/images/pic-google-map.png" alt="" />
                        </picture>
                      )}
                      <p className="preview-content__inner-text location">
                        {event.address.address ? (
                          <a href="#" className="link-addres">
                            {event.address.address.substring(0, 200)}
                            {event.address.address.length > 200 ? "..." : ""}
                          </a>
                        ) : (
                          <span>
                            {preview?.location?.location_not_set_text}
                          </span>
                        )}
                      </p>
                      <a href="#">www.mazoom.sa</a>
                      <span className="date">11.14 AM</span>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              className={`preview-controls__line ${
                event.invitation_show_qr_code ? "qr" : ""
              }`}
              eventKey="tab4"
              title={
                <div className="preview-name">
                  <span>{t("eventEdit.decline")}</span>
                  <IconArrowRight />
                </div>
              }
            >
              <div className="preview-phone preview-phone-decline">
                <div className="preview-content">
                  <div className="preview-content__wrap answer">
                    <div className="preview-content__inner">
                      <div className="preview-answer">
                        <span className="preview-answer__name">Mazoom</span>
                        <div className="preview-answer__info">
                          <span className="caption">Samsons Wedding</span>
                          <p>We are plesed to invite you</p>
                          <picture className="picture">
                            <img src="/images/pic-google-map.png" alt="" />
                          </picture>
                        </div>
                      </div>
                      <p className="preview-answer__info-ans">Decline</p>
                      <span className="date">11.14 AM</span>
                    </div>
                  </div>

                  <div className="preview-content__wrap">
                    <div className="preview-content__inner">
                      <div className="preview-content__inner-text decline">
                        <p>{preview?.decline?.reply_sent_body_text}</p>
                      </div>
                      <a href="#">www.mazoom.sa</a>
                      <span className="date">11.14 AM</span>
                    </div>
                    <div className="preview-invitation__controls">
                      <button className="btn">
                        {preview?.decline?.reply_sent_button_yes}
                      </button>
                      <button className="btn" disabled>
                        {preview?.decline?.reply_sent_button_no}
                      </button>
                    </div>
                  </div>

                  <div className="preview-content__wrap answer">
                    <div className="preview-content__inner">
                      <div className="preview-answer">
                        <span className="preview-answer__name">Mazoom</span>
                        <div className="preview-answer__info no-img">
                          <p>{preview?.decline?.reply_sent_body_text}</p>
                        </div>
                      </div>
                      <p className="preview-answer__info-ans">
                        {preview?.decline?.reply_sent_button_no}
                      </p>
                      <span className="date">11.14 AM</span>
                    </div>
                  </div>

                  <div className="preview-content__wrap">
                    <div className="preview-content__inner">
                      <div className="preview-content__inner-text decline">
                        <p>
                          {
                            preview?.decline
                              ?.send_message_question_accept_reply_text
                          }
                        </p>
                      </div>
                      <a href="#">www.mazoom.sa</a>
                      <span className="date">11.14 AM</span>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default CreateEventPreview
