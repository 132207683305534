import React, { useRef, useEffect, useCallback, useState } from "react"
import { Layer, Text, Transformer } from "react-konva"
import Konva from "konva"
import { URLImage } from "@/components/QrDesign/canvas/QRCodeDownLoad"

type EditableLayerProps = {
  countObj?: any // Replace `any` with the correct type if available
  width: number
  height: number
  handleKeyDown?: (e: any) => void
  handleCounterResize?: (newWidth: number, newHeight: number) => void
  handleTextDblClick: (
    e: any,
    countObj: any,
    setCountObj: React.Dispatch<React.SetStateAction<any>>,
  ) => void
  imageClose?: any
  imageEdit?: any
  setCountObj: React.Dispatch<React.SetStateAction<any>>
  setCountBlock: React.Dispatch<React.SetStateAction<any>>
  canvasArea:any
  show?: boolean
}

const CounterLayer: React.FC<EditableLayerProps> = ({
  countObj,
  handleKeyDown,
  handleTextDblClick,
  handleCounterResize,
  setCountBlock,
  canvasArea,
  imageClose,
  imageEdit,
  setCountObj,
  width,
  height,
  show = false,
}) => {
  const textNodeRef = useRef<any>(null)
  const transformerRef = useRef<any>(null)
  const closeButtonRef = useRef<Konva.Image>(null)
  const editButtonRef = useRef<Konva.Image>(null)
  ////trext
  const [isEditing, setIsEditing] = useState(false)
  const [inputValue, setInputValue] = useState(countObj.textValue)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const handleDoubleClick = (e: any) => {
    setIsEditing(true)
    setTimeout(() => {
      inputRef.current?.focus()
    }, 0)
  }

  // this is theblock
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }
  /////this is thttest
  const [currentSize, setCurrentSize] = useState({
    width: width > 1279 ? 187 : width > 767 ? 125 : 72,
    height: width > 1279 ? 187 : width > 767 ? 125 : 72,
  })
  const handleInputBlur = () => {
    setIsEditing(false)
    setCountObj((prev: any) => ({ ...prev, textValue: inputValue }))
  }
  /////////change
  useEffect(() => {
    if (textNodeRef.current && transformerRef.current) {
      // Attach transformer to the text node
      transformerRef.current.nodes([textNodeRef.current])
      transformerRef.current.getLayer()?.batchDraw()
    }
  }, [countObj]) // Re-run on changes to countObj

  // Handle resizing
  const onTransform = (e: Konva.KonvaEventObject<Event>) => {
    const node = textNodeRef.current;
    if (!node) return;

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    if (scaleX !== 1 || scaleY !== 1) {
      const scale = scaleX !== 1 ? scaleX : scaleY;
      const originalWidth = countObj.width;
      const originalHeight = countObj.height;
      const originalFontSize = countObj.fontSize;

      const newWidth = Math.max(30, originalWidth * scale);
      const newHeight = Math.max(30, originalHeight * scale); // Ensure minimum height of 30
      const fontScalingMultiplier = 1.0;
      const newFontSize = Math.max(8, originalFontSize * scale * fontScalingMultiplier);

      node.scaleX(1);
      node.scaleY(1);
      node.width(newWidth);
      node.height(newHeight);

      setCountObj((prev: any) => ({
        ...prev,
        width: newWidth,
        height: newHeight,
        fontSize: newFontSize
      }));

    } else {
      const newWidth = node.width();
      const newHeight = node.height();

      setCountObj((prev: any) => ({
        ...prev,
        width: newWidth,
        height: newHeight
      }));
    }

    node.getLayer()?.batchDraw();
  };
  const getDragBoundFunc = (nodeRef: React.RefObject<any>) => {
    return (pos: { x: number; y: number }) => {
      if (!canvasArea) return pos;
      
      const node = nodeRef.current;
      const bgRect = canvasArea.getBoundingClientRect();
      
      // Get actual dimensions considering scale and rotation
      const width = node.width() * node.scaleX();
      const height = node.height() * node.scaleY();
      
      // Account for rotation (simplified - assumes rectangular bounds)
      const rotation = node.rotation() || 0;
      const rad = (rotation * Math.PI) / 180;
      const effectiveWidth = Math.abs(width * Math.cos(rad)) + Math.abs(height * Math.sin(rad));
      const effectiveHeight = Math.abs(height * Math.cos(rad)) + Math.abs(width * Math.sin(rad));
      
      // Calculate safe boundaries with padding if needed
      const padding = 2; // Optional padding from edges
      const minX = padding;
      const maxX = Math.max(minX, bgRect.width - effectiveWidth - padding);
      const minY = padding;
      const maxY = Math.max(minY, bgRect.height - effectiveHeight - padding);
      
      // Return bounded position
      return {
        x: Math.max(minX, Math.min(pos.x, maxX)),
        y: Math.max(minY, Math.min(pos.y, maxY))
      };
    };
  };
  const handleDragMove = (e: any) => {
    if (!textNodeRef.current || !canvasArea) return;
    
    const area = canvasArea.getBoundingClientRect();
    const pos = textNodeRef.current.getAbsolutePosition();
    const blockWidth = textNodeRef.current.getWidth();
    const blockHeight = textNodeRef.current.getHeight();
    
    // Ensure we have valid dimensions
    const validWidth = blockWidth || countObj.width;
    const validHeight = blockHeight || countObj.height;
    
    // Calculate bounds with padding to prevent edge sticking
    const padding = 5;
    const x = Math.max(padding, Math.min(pos.x, area.width - validWidth - padding));
    const y = Math.max(padding, Math.min(pos.y, area.height - validHeight - padding));

    // Update pixel positions
    setCountObj((prev: any) => ({
      ...prev,
      x,
      y,
      width: validWidth,
      height: validHeight
    }));

  };

  return (
    <Layer
      draggable={countObj.textEditVisible}
      opacity={countObj.textEditVisible && !show ? 0 : 1}
      onDragStart={(e) => {
        e.cancelBubble = true;
        e.evt.preventDefault();
      }}
      onDragMove={(e) => {
        e.cancelBubble = true;
        e.evt.preventDefault();
        handleDragMove(e);
      }}
      onDragEnd={(e) => {
        e.cancelBubble = true;
        e.evt.preventDefault();
        handleDragMove(e);
      }}
    >
      <URLImage
        opacity={countObj.textEditVisible ? 1 : 0}
        src={imageClose}
        x={countObj.x}
        y={countObj.y - 42}
        funcClick={handleKeyDown}
        width={22}
        height={22}
      />

      <Text
        draggable={countObj.textEditVisible}
        onDragEnd={handleDragMove}
        dragBoundFunc={getDragBoundFunc(textNodeRef)}
        ref={textNodeRef}
        fontFamily={countObj.fontFamily}
        fontStyle={countObj.fontStyle}
        fontSize={countObj.fontSize}
        onTransform={onTransform}
        textDecoration={countObj.textDecoration}
        align={countObj.align}
        y={countObj.y}
        x={countObj.x}
        fill={countObj.fill}
        text={countObj.textValue}
        wrap="word"
        width={countObj.width}
        height={countObj.height}
        onDblTap={(e) => handleTextDblClick(e, countObj, setCountObj)}
        onDblClick={(e) => handleTextDblClick(e, countObj, setCountObj)}
        sceneFunc={(context, shape) => {
          const radius = 4
          context.fillStyle = countObj.color
          context.beginPath()
          context.moveTo(radius, -15)
          context.lineTo(shape.width() - radius, -15)
          context.arcTo(shape.width(), -15, shape.width(), -15 + radius, radius)
          context.lineTo(shape.width(), shape.height() - radius - 15)
          context.arcTo(
            shape.width(),
            shape.height() - 15,
            shape.width() - radius,
            shape.height() - 15,
            radius,
          )
          context.lineTo(radius, shape.height() - 15)
          context.arcTo(
            0,
            shape.height() - 15,
            0,
            shape.height() - radius - 15,
            radius,
          )
          context.lineTo(0, -15 + radius)
          context.arcTo(0, -15, radius, -15, radius)
          context.closePath()
          context.fill()
          ;(shape as Konva.Text)._sceneFunc(context)
        }}
      />
      {/* Transformer for resizing */}
      <Transformer
        ref={transformerRef}
        // onTransformEnd={onTransform}
        rotateEnabled={false}
        enabledAnchors={[
          "middle-left",
          "middle-right",
          "top-center", // Enables vertical scaling
          "bottom-center", // Enables vertical scaling
        ]}
        boundBoxFunc={(oldBox, newBox) => {
          const MIN_SIZE = 30
          const MAX_SIZE = 300
          return {
            ...newBox,
            width: Math.max(MIN_SIZE, Math.min(MAX_SIZE, newBox.width)),
            height: Math.max(MIN_SIZE, Math.min(MAX_SIZE, newBox.height)),
          }
        }}
      />
    </Layer>
  )
}

export { CounterLayer }
